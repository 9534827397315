/* =================================================

Template Name: 
Author: MD: Asanur Rahman
Author URI: 
Version: 1.0
Design and Developed by: MD: Asanur Rahman

=================================================== */
/*---------- 1. Default css starts ------------*/

/*default css ends */
.serviceCallToActionHeader{
    background: url(../images/banner_img.png) no-repeat 0 0;
    background-size: 100% 100%;
    padding-bottom: 170px;
    min-height: 100vh
}
.main_side_nav{
    margin-top: 70px;
}
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 18px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}
.nav_icon {
	color: #313131;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
}
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}
@media screen and (max-width: 768px){
    .main_side_nav {
        margin-top: 25px;
        position: absolute;
        right: 0;
    }
}
/* Side Nav end */
.navbar-brand {
	margin-top: 50px;
}
/* Navigation End */

.banner_btn.btn.btn-success {
	background: #33e2a0;
	color: #fff;
	font-weight: 700;
	border: none;
	border-radius: 50px;
    padding: 10px 30px;
    font-size: 2em
}
.banner_btn.btn.btn-success:hover {
	box-shadow: 5px 5px 8px #bddcd0;
}
.banner_content h1 {
	color: #9974E0;
	font-size: 60px;
    margin-top: 160px;
    text-transform: uppercase
}
.banner_content p {
	color: #1B0D3D;
	margin: 50px 0;
	font-size: 26px;
    font-weight: 400;
    padding: 0 170px 0 0;
    line-height: 1.6em
}
.banner_right_content {
    margin-top: 150px;
}

.banner_right_content > img {
    max-height: 50vh
}

/* Custom Css End */

@media screen and (max-width: 360px){
    header {
        padding-bottom: 100px;
    }
    .navbar-brand {
        margin-top: 20px;
        width: 50%;
    }
    .main_side_nav {
        margin-top: 35px;
        position: absolute;
        right: 20px;
    }
    .banner_content{
        text-align: center;
    }
    .banner_content h1 {
        font-size: 24px;
        margin-top: 40px;
    }
    .banner_content h1 {
        font-size: 24px;
        margin-top: 40px;
    }
    .banner_content p {
        margin: 30px 0;
        font-size: 16px;
        padding: 0;
    }
    .banner_right_content {
        margin: 50px !important;
        position: absolute;
        top: -560px;
        width: 60%;
        
    }
    .banner_right_content > img {
        width: 150px;
        height: 100px  
    }
    .col-sm-12.banner_left_content {
        margin-top: 235px;
    }
}
@media (min-width: 361px) and (max-width: 768px){
    header {
        padding-bottom: 100px;
    }
    .navbar-brand {
        margin-top: 20px;
        width: 50%;
    }
    .main_side_nav {
        margin-top: 35px;
        position: absolute;
        right: 20px;
    }
    .banner_content{
        text-align: center;
    }
    .banner_content h1 {
        font-size: 24px;
        margin-top: 40px;
    }
    .banner_content h1 {
        font-size: 24px;
        margin-top: 40px;
    }
    .banner_content p {
        margin: 30px 0;
        font-size: 16px;
        padding: 0;
    }
    .banner_right_content {
        position: absolute;
        width: 100%;
        top: -600px;
        left: 0;
        margin-top: 75px
    }
    .banner_right_content > img {
        width: 80vw;
        padding: 0 10vw 0 10vw;
        height: 20vh;    
    }
    .col-sm-12.banner_left_content {
        margin-top: 330px;
    }
}