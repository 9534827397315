/*
Template: Markethon - Digital Marketing Agency Responsive HTML5 Template
Author: iqonicthemes.in
Design and Developed by: iqonicthemes.in
NOTE: This file contains the styling for responsive Template (typography.css)
*/

/*================================================
[  Table of contents  ]
================================================

:: Google Font
:: General
:: Text color
:: Extra class
:: Buttons
:: Page Section Margin Padding 
:: Background  color
:: BG - Images
:: Background overlay color
:: Back to Top
:: Loader

======================================
[ End table content ]
======================================*/

/*---------------------------------------------------------------------
Google Font
-----------------------------------------------------------------------*/
@import url('https://fonts.googleapis.com/css?family=Nunito:200,300,400,600,700,800,900');
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500');

/*---------------------------------------------------------------------
Import Css
-----------------------------------------------------------------------*/
@import url("all.min.css");
@import url("magnific-popup.css");
@import url("ionicons.min.css");
@import url("timeline.css");
@import url("wow.css");
@import url("mega_menu.css");

/*---------------------------------------------------------------------
Loader
-----------------------------------------------------------------------*/
#loading {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #ffffff;
	z-index: 9999;
}

#loading img {
	width: 150px;
}

/*---------------------------------------------------------------------
General
-----------------------------------------------------------------------*/
*::-moz-selection {
	background: #7c60d5;
	color: #fff;
	text-shadow: none;
}

::-moz-selection {
	background: #7c60d5;
	color: #fff;
	text-shadow: none;
}

::selection {
	background: #7c60d5;
	color: #fff;
	text-shadow: none;
}

body {
	font-family: 'Poppins', sans-serif;
	font-weight: normal;
	font-style: normal;
	font-size: 16px;
	line-height: 32px;
	color: #868894;
	letter-spacing: 1px;
	overflow-x: hidden;
}

a, .btn {
	-webkit-transition: all 0.5s ease-out 0s;
	-moz-transition: all 0.5s ease-out 0s;
	-ms-transition: all 0.5s ease-out 0s;
	-o-transition: all 0.5s ease-out 0s;
	transition: all 0.5s ease-out 0s;
}

a:focus {
	text-decoration: none !important;
}

a:focus, a:hover {
	color: #33e2a0;
	text-decoration: none !important;
}

a, button, input {
	outline: medium none !important;
	color: #1b0e3d;
}

.uppercase {
	text-transform: uppercase;
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Nunito', sans-serif;
	font-weight: normal;
	color: #1b0e3d;
	margin-top: 0px;
	font-weight: bold;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
	color: inherit;
}

label {
	font-weight: normal;
}

h1 {
	font-size: 52px;
	font-style: normal;
	line-height: 62px;
}

h2 {
	font-size: 40px;
	font-style: normal;
	line-height: 50px;
}

h3 {
	font-size: 30px;
	font-style: normal;
	line-height: 40px;
}

h4 {
	font-size: 26px;
	font-style: normal;
	line-height: 36px;
}

h5 {
	font-size: 24px;
	font-style: normal;
	line-height: 34px;
}

h6 {
	font-size: 20px;
	font-style: normal;
	line-height: 30px;
}

/*----------------------------------------------
Listing
------------------------------------------------*/
ul {
	padding: 0;
	margin: 0;
	padding-left: 20px;
}

ul li:after {
	position: absolute;
	color: #02d871;
	content: "";
	font-family: "Ionicons";
	left: 0;
	top: 2px;
	font-size: 30px;
	font-weight: normal;
}

ul.listing-mark {
	padding: 0;
	margin: 0;
}

ul.listing-mark li {
	position: relative;
	list-style-type: none;
	padding-left: 40px;
	margin: 20px 0;
	color: #1b0e3d;
	font-weight: 600;
	font-size: 16px;
}

ul.listing-mark li:after {
	content: "\f3fe";
}

ul.listing-mark li:hover:after {
	content: "\f3ff";
}

/*----------------------------------------------
Font Weight
------------------------------------------------*/
.iq-fw-1 {
	font-weight: 100;
}

.iq-fw-2 {
	font-weight: 200;
}

.iq-fw-3 {
	font-weight: 300;
}

.iq-fw-4 {
	font-weight: 400;
}

.iq-fw-5 {
	font-weight: 500;
}

.iq-fw-6 {
	font-weight: 600;
}

.iq-fw-7 {
	font-weight: 700;
}

.iq-fw-8 {
	font-weight: 800;
}

.iq-fw-9 {
	font-weight: 900;
}

/*----------------------------------------------
Letter Spacing
------------------------------------------------*/
.iq-ls-1 {
	letter-spacing: 1px;
}

.iq-ls-2 {
	letter-spacing: 2px;
}

.iq-ls-3 {
	letter-spacing: 3px;
}

.iq-ls-4 {
	letter-spacing: 4px;
}

.iq-ls-5 {
	letter-spacing: 5px;
}

.iq-ls-6 {
	letter-spacing: 6px;
}

.iq-ls-7 {
	letter-spacing: 7px;
}

.iq-ls-8 {
	letter-spacing: 8px;
}

.iq-ls-9 {
	letter-spacing: 9px;
}

/*----------------------------------------------
Section padding 
------------------------------------------------*/
.overview-block-ptb {
	padding: 0px 0;
	display: inline-block;
	width: 100%;
	clear: both;
	float: left;
}

.overview-block-pt {
	padding: 80px 0 0;
	display: inline-block;
	width: 100%;
	clear: both;
	float: left;
}

.overview-block-pb {
	padding: 0 0 80px;
	display: inline-block;
	width: 100%;
	clear: both;
	float: left;
}

.overview-block-pb5 {
	padding-bottom: 50px;
}

/*---------------------------------------------------------------------
Extra class
---------------------------------------------------------------------*/
.overflow-h {
	overflow: hidden;
}

section {
	background: #ffffff;
	padding: 80px 0;
	display: inline-block;
	width: 100%;
	clear: both;
	float: left;
}

ul {
	margin: 0px;
	padding: 0px;
}

hr {
	margin: 0;
	padding: 0px;
	border-bottom: 1px solid #ccc;
	display: inline-block;
	width: 100%;
	border-top: 0px;
}

.no-bg {
	background: none;
}

/*---------------------------------------------------------------------
Font Size
-----------------------------------------------------------------------*/
.subtitle {
	font-size: 16px;
}

.iq-font-14 {
	font-size: 14px;
	line-height: 24px;
}

.iq-font-18 {
	font-size: 18px;
	line-height: 28px
}

.iq-font-24 {
	font-size: 24px;
	line-height: 36px
}

/*---------------------------------------------------------------------
Text color
-----------------------------------------------------------------------*/
.main-color {
	color: #7c60d5;
}

.text-white {
	color: #ffffff;
}

.text-black {
	color: #1b0e3d;
}

.text-gray {
	color: #868894;
}

.text-red {
	color: #b02501;
}

.text-green {
	color: #33e2a0;
}

/*---------------------------------------------------------------------
Background  color
-----------------------------------------------------------------------*/
.white-bg {
	background: #ffffff;
}

.gray-bg {
	background: #edecf0;
}

.light-gray-bg {
	background: #f1f1f1;
}

.main-bg {
	background: #7c60d5;
}

.black-bg {
	background: #1b0e3d;
}

.green-bg {
	background: #33e2a0;
}

.light-main-bg {
	background: #8064d9;
}

/*************************
   BG - Images
*************************/
.parallax {
	background-size: cover !important;
	-webkit-background-size: cover !important;
	-moz-background-size: cover !important;
	-ms-background-size: cover !important;
	position: relative;
	z-index: 0;
	background-origin: initial;
	background-position: center center !important;
	background-repeat: no-repeat;
}

/*---------------------------------------------------------------------
Background overlay color
-----------------------------------------------------------------------*/

/* Background Gradient Black */
.bg-over-black-10:before {
	background: rgb(27, 14, 61, 0.1);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.bg-over-black-20:before {
	background: rgb(27, 14, 61, 0.2);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.bg-over-black-30:before {
	background: rgb(27, 14, 61, 0.3);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.bg-over-black-40:before {
	background: rgb(27, 14, 61, 0.4);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.bg-over-black-50:before {
	background: rgb(27, 14, 61, 0.5);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.bg-over-black-60:before {
	background: rgb(27, 14, 61, 0.6);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.bg-over-black-70:before {
	background: rgb(27, 14, 61, 0.7);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.bg-over-black-80:before {
	background: rgb(27, 14, 61, 0.8);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.bg-over-black-90:before {
	background: rgb(27, 14, 61, 0.9);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

/* Background Gradient White */
.bg-over-white-10:before {
	background: rgba(255, 255, 255, 0.1);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.bg-over-white-20:before {
	background: rgba(255, 255, 255, 0.2);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.bg-over-white-30:before {
	background: rgba(255, 255, 255, 0.3);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.bg-over-white-40:before {
	background: rgba(255, 255, 255, 0.4);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.bg-over-white-50:before {
	background: rgba(255, 255, 255, 0.5);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.bg-over-white-60:before {
	background: rgba(255, 255, 255, 0.6);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.bg-over-white-70:before {
	background: rgba(255, 255, 255, 0.7);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.bg-over-white-80:before {
	background: rgba(255, 255, 255, 0.8);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.bg-over-white-90:before {
	background: rgba(255, 255, 255, 0.9);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

/* Background Gradient Main */
.bg-over-main-10:before {
	background: rgba(171, 239, 248, 0.1);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.bg-over-main-20:before {
	background: rgba(171, 239, 248, 0.2);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.bg-over-main-30:before {
	background: rgba(171, 239, 248, 0.3);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.bg-over-main-40:before {
	background: rgba(171, 239, 248, 0.4);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.bg-over-main-50:before {
	background: rgba(171, 239, 248, 0.5);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.bg-over-main-60:before {
	background: rgba(171, 239, 248, 0.6);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.bg-over-main-70:before {
	background: rgba(171, 239, 248, 0.7);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.bg-over-main-80:before {
	background: rgba(171, 239, 248, 0.8);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.bg-over-main-90:before {
	background: rgba(171, 239, 248, 0.9);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

/*----------------------------------------------------------------------
 Buttons
-----------------------------------------------------------------------*/
.button {
	color: #ffffff;
	cursor: pointer;
	text-transform: capitalize;
	font-weight: 700;
	border: none;
	position: relative;
	background: #33e2a0;
	font-family: 'Nunito', sans-serif;
	display: inline-block;
	-webkit-border-radius: 90px;
	-moz-border-radius: 90px;
	border-radius: 90px;
}

.button:hover, .button:focus {
	color: #ffffff;
	background: #1b0e3d;
	outline: none;
	border-color: #1b0e3d;
}

.button-sm {
	padding: 13px 25px;
}

.slide-button {
	display: inline-block;
	height: 50px;
	color: #ffffff;
	text-decoration: none;
	overflow: hidden;
	text-align: center;
	z-index: 4;
}

.first, .second {
	padding: 0 30px;
	line-height: 50px;
	-webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}

.first {
	background-color: #33e2a0;
	color: #fff;
}

.slide-button:hover .first {
	margin-top: -50px;
}

.button.bt-border  .first {
	background: none;
	color: #7c60d5;
}

.bt-subscribe .first, .bt-subscribe  .second {
	line-height: 48px;
}

.bt-subscribe.btn-comming {
	height: 45px;
}

.bt-subscribe.btn-comming .first, .bt-subscribe.btn-comming .second {
	line-height: 45px;
}

.bt-subscribe.btn-comming:hover .first {
	margin-top: -45px;
}

/* Buttons green */
.button.bt-border {
	color: #7c60d5;
	background: #ffffff;
	border: 2px solid #7c60d5;
}

.button.bt-border:hover, .button.bt-border:focus {
	color: #33e2a0;
	background: #ffffff;
	outline: none;
	border: 2px solid #33e2a0;
}

/* Subscribe Button */
.bt-subscribe {
	position: absolute;
	right: 5px;
	bottom: 5px;
}

footer.footer-one .bt-subscribe {
	position: absolute;
	right: 4px;
	bottom: 4px;
}

/*----------------------------------------------------------------------
 Border
-----------------------------------------------------------------------*/
.border-green {
	border-color: #33e2a0;
}

/*----------------------------------------------------------------------
 Form
-----------------------------------------------------------------------*/
input, input.form-control {
	border: 1px solid #e4e4e4;
	font-size: 16px;
	height: 55px;
	padding-left: 30px;
	margin-bottom: 35px;
	-webkit-border-radius: 90px;
	-moz-border-radius: 90px;
	border-radius: 90px;
	-webkit-box-shadow: 0px 13px 25px 0px rgba(77, 54, 206, 0.2);
	-moz-box-shadow: 0px 13px 25px 0px rgba(77, 54, 206, 0.2);
	box-shadow: 0px 13px 25px 0px rgba(77, 54, 206, 0.2);
	padding-right: 35%;
}

input[type="checkbox"], input.form-control[type="checkbox"] {
	margin-top: 0.5rem;
	background: #fff;
	height: auto;
	border: 1px solid #33e2a0;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
}

::-webkit-input-placeholder {

	/* Chrome/Opera/Safari */
	color: #868894;
}

::-moz-placeholder {

	/* Firefox 19+ */
	color: #868894;
}

:-ms-input-placeholder {

	/* IE 10+ */
	color: #868894;
}

:-moz-placeholder {

	/* Firefox 18- */
	color: #868894;
}

textarea.form-control {
	height: 230px;
	padding: 15px 30px;
	-webkit-border-radius: 25px;
	-moz-border-radius: 25px;
	border-radius: 25px;
	-webkit-box-shadow: 0px 13px 25px 0px rgba(77, 54, 206, 0.2);
	-moz-box-shadow: 0px 13px 25px 0px rgba(77, 54, 206, 0.2);
	box-shadow: 0px 13px 25px 0px rgba(77, 54, 206, 0.2);
}

input:hover, input.form-control:hover, input:focus, input.form-control:focus, textarea.form-control:hover, textarea.form-control:focus {
	border: 1px solid #7c60d5;
	-webkit-box-shadow: 0px 0px 25px 0px rgba(77, 54, 206, 0.2);
	-moz-box-shadow: 0px 0px 25px 0px rgba(77, 54, 206, 0.2);
	box-shadow: 0px 0px 25px 0px rgba(77, 54, 206, 0.2);
}

footer.footer-two .subscribe-form input[type=email]::-webkit-input-placeholder {
	color: #ffffff;
}

footer.footer-two .subscribe-form input[type=email]::-moz-placeholder {
	color: #ffffff;
}

footer.footer-two .subscribe-form input[type=email]:-ms-input-placeholder {
	color: #ffffff;
}

/*---------------------------------------------------------------------
Back to Top
-----------------------------------------------------------------------*/
#back-to-top .top {
	position: absolute;
	margin: 0px;
	color: #ffffff;
	bottom: 0;
	right: 10px;
	z-index: 9;
	font-weight: 600;
	overflow: hidden;
	background: #7c60d5;
	padding: 15px 18px 5px 18px;
	border-radius: 100px 100px 0 0;
}

#back-to-top .top i {
	font-size: 28px;
	vertical-align: middle;
}

#back-to-top:hover .top i {
	top: 0;
}

#back-to-top .top:hover {
	background: #33e2a0;
}

.footer-three #back-to-top .top {
	position: absolute;
	margin: 0px;
	color: #ffffff;
	bottom: 4%;
	right: 0;
	z-index: 9;
	font-weight: 600;
	overflow: hidden;
	background: #7c5fd5;
	width: 60px;
	padding: 5px;
	text-align: center;
	border-radius: 100px 0 0 100px;
}

/*---------------------------------------------------------------------
Section Title
-----------------------------------------------------------------------*/
.section-title {
	margin-bottom: 60px;
}

.section-title p {
	margin: 0 !important;
}

.title {
	z-index: 2;
	position: relative;
}