/*
Template: Markethon - Digital Marketing Agency Responsive HTML5 Template
Author: iqonicthemes.in
Design and Developed by: iqonicthemes.in
NOTE: This file contains the styling for responsive Template.
*/
@import url(./menu_menu_reset.min.css);

.mega-menu {
	margin: 0 auto;
	padding: 0;
	display: block;
	float: none;
	position: relative;
	z-index: 999;
	max-width: 1280px;
	width: 100%;
	font-size: 16px;
	font-family: "Open Sans", sans-serif;
	min-height: 50px;
	clear: both;
	box-sizing: border-box
}

@media screen and (max-width:1023px) {
	.mega-menu {
		float: left;
		margin: 0;
		min-width: 200px
	}

}

.mega-menu * {
	outline: none;
	list-style: none;
	text-decoration: none;
	box-sizing: border-box !important;
	font-family: "Open Sans", sans-serif;
	font-weight: 400;
	-webkit-tap-highlight-color: transparent;
	text-align: left
}

@media screen and (max-width:1023px) {
	.mega-menu * {
		word-break: break-all
	}

}

.mega-menu i.fas {
	font-family: "Font Awesome 5 Free";
	font-weight: 900
}

.mega-menu img {
	margin: 0;
	padding: 0;
	display: block;
	max-width: 100% !important;
	float: left
}

.mega-menu input {
	border: none
}

.mega-menu > section.menu-list-items {
	margin: 0;
	padding: 0;
	display: block;
	float: left;
	width: 100%;
	background-color: #333
}

@media screen and (min-width:1024px) {
	.mega-menu.menuFullWidth.vertical-left > section.menu-list-items {
		max-width: 250px
	}

	.mega-menu.menuFullWidth.vertical-right > section.menu-list-items {
		max-width: 250px
	}

}

.mega-menu .menu-logo {
	margin: 0;
	padding: 0;
	display: block;
	float: left;
	position: relative
}

@media screen and (min-width:1024px) {
	.mega-menu .menu-logo.menu-logo-align-right {
		float: right
	}

}

@media screen and (max-width:1023px) {
	.mega-menu .menu-logo {
		width: 100%
	}

}

.mega-menu .menu-logo > li {
	margin: 0;
	padding: 0;
	display: block;
	float: left;
	font-size: 1em;
	line-height: 50px
}

@media screen and (max-width:1023px) {
	.mega-menu .menu-logo > li {
		width: 100%;
		line-height: normal
	}

}

.mega-menu .menu-logo > li > a {
	margin: 0;
	display: inline-block;
	float: left;
	width: 100%;
	color: #fff;
	font-size: 0.8125em;
	padding: 0 20px 0 45px;
	line-height: 50px;
	transition: background-color 200ms ease
}

.mega-menu .menu-logo > li > a:hover {
	background-color: #ff6347
}

@media screen and (max-width:1023px) {
	.mega-menu .menu-logo > li > a {
		line-height: normal;
		padding-top: 16px;
		padding-bottom: 16px;
		position: relative;
		z-index: 10;
		width: auto
	}

}

.mega-menu .menu-logo > li > a i.fas {
	padding: 0;
	display: inline-block;
	font-size: 1.25em;
	position: absolute;
	top: 0;
	left: 20px;
	bottom: 0;
	margin: auto 0;
	line-height: 50px
}

.mega-menu .menu-logo > li > a img {
	width: 20px;
	height: 20px;
	position: absolute;
	top: 0;
	left: 15px;
	bottom: 0;
	margin: auto 0
}

.mega-menu .menu-links {
	margin: 0;
	padding: 0;
	display: block;
	float: left
}

@media screen and (max-width:1023px) {
	.mega-menu .menu-links {
		width: 100%
	}

}

@media screen and (min-width:1024px) {
	.mega-menu .menu-links {
		display: block !important;
		max-height: 100% !important;
		overflow: visible !important
	}

	.mega-menu .menu-links.menu-links-align-right {
		float: right
	}

}

.mega-menu .menu-links > li {
	margin: 0;
	padding: 0;
	display: block;
	float: left;
	transition: background-color 200ms ease;
	font-size: 1em
}

@media screen and (max-width:1023px) {
	.mega-menu .menu-links > li {
		width: 100%;
		position: relative;
		cursor: pointer;
		line-height: normal;
		z-index: 50
	}

	.mega-menu .menu-links > li.activeTriggerMobile {
		background-color: #ff6347
	}

}

@media screen and (min-width:1024px) {
	.mega-menu .menu-links > li.activeTrigger {
		background-color: #ff6347
	}

}

.mega-menu .menu-links > li.active {
	background-color: #ff6347
}

.mega-menu .menu-links > li:hover {
	background-color: #ff6347
}

.mega-menu .menu-links > li > a {
	margin: 0;
	padding: 0 20px;
	display: inline-block;
	float: none;
	width: 100%;
	color: #fff;
	font-size: 0.8125em;
	line-height: 50px;
	position: relative
}

@media screen and (max-width:1023px) {
	.mega-menu .menu-links > li > a {
		width: auto;
		line-height: normal;
		padding-top: 15px;
		padding-bottom: 15px;
		position: static;
		padding-right: 10px;
		z-index: 20
	}

}

.mega-menu .menu-links > li > a i.fas {
	font-size: 1em;
	line-height: 0.8125em;
	padding-right: 2px
}

.mega-menu .menu-links > li > a i.fa.fa-indicator {
	padding-right: 0;
	padding-left: 2px
}

@media screen and (max-width:1023px) {
	.mega-menu .menu-links > li > a i.fa.fa-indicator {
		float: right;
		position: absolute;
		right: 20px;
		top: 0;
		bottom: 0;
		line-height: 50px;
		height: 50px;
		z-index: -1
	}

}

.mega-menu .menu-social-bar {
	margin: 0;
	display: block;
	float: left;
	padding: 0 10px
}

@media screen and (min-width:1024px) {
	.mega-menu .menu-social-bar {
		display: block !important
	}

}

@media screen and (min-width:1024px) {
	.mega-menu .menu-social-bar.menu-social-bar-right {
		float: right
	}

}

@media screen and (max-width:1023px) {
	.mega-menu .menu-social-bar {
		width: 100%;
		text-align: center
	}

}

.mega-menu .menu-social-bar > li {
	margin: 0;
	padding: 0;
	display: block;
	float: left;
	font-size: 1em;
	line-height: 50px
}

.mega-menu .menu-social-bar > li[data-color='blue'] > a:hover {
	background-color: #3b5998
}

.mega-menu .menu-social-bar > li[data-color='sky-blue'] > a:hover {
	background-color: #2caae1
}

.mega-menu .menu-social-bar > li[data-color='orange'] > a:hover {
	background-color: #dd4b39
}

.mega-menu .menu-social-bar > li[data-color='red'] > a:hover {
	background-color: #bd081c
}

@media screen and (max-width:1023px) {
	.mega-menu .menu-social-bar > li {
		float: none;
		display: inline-block;
		margin-bottom: -5px
	}

}

.mega-menu .menu-social-bar > li > a {
	margin: 0;
	display: inline-block;
	float: left;
	width: 100%;
	color: #fff;
	font-size: 0.875em;
	padding: 0 5px;
	transition: background-color 200ms ease;
	line-height: 50px
}

@media screen and (max-width:1023px) {
	.mega-menu .menu-social-bar > li > a {
		padding-left: 10px;
		padding-right: 10px
	}

}

.mega-menu .menu-social-bar > li > a i.fas {
	margin: 0;
	padding: 0;
	display: inline-block;
	float: left;
	width: 100%;
	font-size: 1.125em;
	line-height: 50px
}

.mega-menu .menu-search-bar {
	margin: 0;
	padding: 0;
	display: block;
	float: right;
	position: relative;
	height: 50px
}

@media screen and (min-width:1024px) {
	.mega-menu .menu-search-bar.menu-search-bar-left {
		float: left
	}

}

@media screen and (max-width:1023px) {
	.mega-menu .menu-search-bar {
		width: 100%;
		position: absolute;
		top: 0;
		right: 0
	}

}

.mega-menu .menu-search-bar li, .mega-menu .menu-search-bar form, .mega-menu .menu-search-bar label {
	margin: 0;
	padding: 0;
	display: block;
	float: left;
	width: 100%;
	line-height: 50px
}

.mega-menu .menu-search-bar li:hover i.fas.fa-search {
	background: #ff6347
}

.mega-menu .menu-search-bar input {
	max-width: 0;
	width: 100%;
	margin: 0;
	padding: 5px 50px 5px 0;
	font-size: 0.8125em;
	transition: max-width 400ms ease, background 400ms ease, padding-left 400ms ease;
	height: 50px;
	display: block;
	background: none;
	color: #fff;
	font-weight: 400
}

.mega-menu .menu-search-bar input:focus {
	max-width: 170px;
	background: #ff6347;
	padding-left: 20px
}

@media screen and (max-width:1023px) {
	.mega-menu .menu-search-bar input:focus {
		max-width: 100%;
		position: relative;
		z-index: 20
	}

}

.mega-menu .menu-search-bar i.fas.fa-search {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: 50px;
	text-align: center;
	line-height: 50px;
	color: #fff;
	cursor: text;
	transition: background 200ms ease;
	z-index: 40
}

.mega-menu .menu-search-bar:-moz-placeholder {
	opacity: 1 !important;
	color: #fff !important
}

.mega-menu .menu-search-bar::-moz-placeholder {
	opacity: 1 !important;
	color: #fff !important
}

.mega-menu .menu-search-bar:-ms-input-placeholder {
	opacity: 1 !important;
	color: #fff !important
}

.mega-menu .menu-search-bar::-webkit-input-placeholder {
	opacity: 1 !important;
	color: #fff !important
}

@media screen and (max-width:1023px) {
	.mega-menu .menu-search-bar.active input {
		padding-right: 150px
	}

	.mega-menu .menu-search-bar.active i.fas.fa-search {
		right: 70px
	}

}

.mega-menu .menu-mobile-collapse-trigger {
	margin: 0;
	padding: 0;
	height: 50px;
	width: 70px;
	background: #1a1a1a;
	display: none;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 100;
	float: right;
	cursor: pointer;
	transition: background 200ms ease
}

.mega-menu .menu-mobile-collapse-trigger:hover {
	background: black
}

.mega-menu .menu-mobile-collapse-trigger:before, .mega-menu .menu-mobile-collapse-trigger:after, .mega-menu .menu-mobile-collapse-trigger span {
	content: "";
	display: block;
	height: 4px;
	width: 40px;
	background: #fff;
	position: absolute;
	top: 13px;
	left: 0;
	right: 0;
	margin: 0 auto;
	transition: opacity 400ms ease, -webkit-transform 400ms ease 0s;
	transition: transform 400ms ease 0s, opacity 400ms ease;
	transition: transform 400ms ease 0s, opacity 400ms ease, -webkit-transform 400ms ease 0s;
	-webkit-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transform-origin: 0px 50% 0px;
	-ms-transform-origin: 0px 50% 0px;
	transform-origin: 0px 50% 0px
}

.mega-menu .menu-mobile-collapse-trigger:after {
	top: 33px
}

.mega-menu .menu-mobile-collapse-trigger span {
	top: 23px
}

.mega-menu .menu-mobile-collapse-trigger.active span {
	opacity: 0
}

.mega-menu .menu-mobile-collapse-trigger.active:before {
	-webkit-transform: rotate(30deg);
	-ms-transform: rotate(30deg);
	transform: rotate(30deg)
}

.mega-menu .menu-mobile-collapse-trigger.active:after {
	-webkit-transform: rotate(-30deg);
	-ms-transform: rotate(-30deg);
	transform: rotate(-30deg)
}

@media screen and (max-width:1023px) {
	.mega-menu .menu-mobile-collapse-trigger {
		display: block
	}

}

.mega-menu .drop-down-multilevel {
	margin: 0;
	padding: 0;
	display: block;
	position: absolute;
	top: auto;
	left: auto;
	right: auto;
	z-index: 50;
	width: 100%;
	background: #fff;
	float: left;
	max-width: 200px
}

.mega-menu .drop-down-multilevel * {
	color: #555
}

@media screen and (min-width:1024px) {
	.mega-menu .drop-down-multilevel {
		display: block !important;
		opacity: 0;
		visibility: hidden
	}

}

@media screen and (max-width:1023px) {
	.mega-menu .drop-down-multilevel {
		max-width: 100% !important;
		position: relative;
		left: 0;
		top: 0;
		padding: 10px;
		display: none
	}

}

.mega-menu .drop-down-multilevel li {
	margin: 0;
	padding: 0;
	display: block;
	float: left;
	width: 100%;
	position: relative;
	transition: background 200ms ease;
	z-index: 50
}

.mega-menu .drop-down-multilevel li:hover {
	background: #ff6347
}

.mega-menu .drop-down-multilevel li:hover > a {
	color: #fff
}

.mega-menu .drop-down-multilevel li:hover > a i.fas {
	color: #fff
}

@media screen and (min-width:1024px) {
	.mega-menu .drop-down-multilevel li.activeTrigger {
		background: #ff6347
	}

	.mega-menu .drop-down-multilevel li.activeTrigger > a {
		color: #fff
	}

	.mega-menu .drop-down-multilevel li.activeTrigger > a i.fas {
		color: #fff
	}

}

@media screen and (max-width:1023px) {
	.mega-menu .drop-down-multilevel li.activeTriggerMobile {
		background: #ff6347
	}

	.mega-menu .drop-down-multilevel li.activeTriggerMobile > a {
		color: #fff
	}

	.mega-menu .drop-down-multilevel li.activeTriggerMobile > a i.fas {
		color: #fff
	}

}

.mega-menu .drop-down-multilevel a {
	margin: 0;
	padding: 15px 20px;
	font-size: 0.8125em;
	display: inline-block;
	float: left;
	width: 100%;
	color: #555;
	transition: color 200ms ease;
	min-height: 48px
}

@media screen and (max-width:1023px) {
	.mega-menu .drop-down-multilevel a {
		width: auto
	}

}

.mega-menu .drop-down-multilevel i.fas {
	float: left;
	line-height: 1.375em;
	font-size: 1em;
	display: block;
	padding-right: 10px;
	transition: color 200ms ease
}

.mega-menu .drop-down-multilevel i.fas.fa-indicator {
	float: right;
	line-height: 1.375em;
	font-size: 1em;
	display: block;
	padding-left: 10px;
	padding-right: 0
}

@media screen and (max-width:1023px) {
	.mega-menu .drop-down-multilevel i.fas.fa-indicator {
		float: right;
		height: 50px;
		position: absolute;
		top: 0;
		right: 20px;
		line-height: 50px;
		z-index: -1
	}

}

.mega-menu .drop-down-multilevel .drop-down-multilevel {
	left: 100%;
	top: 0
}

@media screen and (min-width:1024px) {
	.mega-menu .drop-down-multilevel .drop-down-multilevel.left-side {
		left: -100%
	}

}

@media screen and (max-width:1023px) {
	.mega-menu .drop-down-multilevel .drop-down-multilevel {
		left: 0;
		border: 1px solid #cccccc
	}

}

.mega-menu .drop-down, .mega-menu .drop-down-tab-bar {
	position: absolute;
	left: auto;
	top: auto;
	right: auto;
	background: #fff;
	float: left;
	padding: 10px;
	z-index: 999;
	display: block;
	cursor: default;
	overflow: hidden
}

.mega-menu .drop-down *, .mega-menu .drop-down-tab-bar * {
	color: #555
}

@media screen and (min-width:1024px) {
	.mega-menu .drop-down, .mega-menu .drop-down-tab-bar {
		display: block !important;
		opacity: 0;
		visibility: hidden
	}

	.mega-menu.vertical-left.desktopTopFixed .drop-down, .mega-menu.vertical-left.desktopTopFixed .drop-down-tab-bar {
		margin-left: 0 !important;
		margin-right: 0 !important
	}

	.mega-menu.vertical-right.desktopTopFixed .drop-down, .mega-menu.vertical-right.desktopTopFixed .drop-down-tab-bar {
		margin-left: 0 !important;
		margin-right: 0 !important
	}

}

@media screen and (max-width:1023px) {
	.mega-menu .drop-down, .mega-menu .drop-down-tab-bar {
		width: 100% !important;
		top: 0;
		left: 0;
		position: relative;
		display: none
	}

}

.mega-menu .drop-down .grid-row, .mega-menu .drop-down-tab-bar .grid-row {
	margin: 0;
	padding: 0;
	min-height: 1px;
	width: 100%;
	float: left;
	clear: both
}

.mega-menu .drop-down [class*="grid-col-"], .mega-menu .drop-down-tab-bar [class*="grid-col-"] {
	margin: 0;
	float: left;
	padding: 10px;
	position: relative
}

.mega-menu .drop-down .grid-col-1, .mega-menu .drop-down-tab-bar .grid-col-1 {
	width: 8.333333333333333%
}

.mega-menu .drop-down .grid-col-2, .mega-menu .drop-down-tab-bar .grid-col-2 {
	width: 16.66666666666667%
}

.mega-menu .drop-down .grid-col-3, .mega-menu .drop-down-tab-bar .grid-col-3 {
	width: 25%
}

.mega-menu .drop-down .grid-col-4, .mega-menu .drop-down-tab-bar .grid-col-4 {
	width: 33.33333333333333%
}

.mega-menu .drop-down .grid-col-5, .mega-menu .drop-down-tab-bar .grid-col-5 {
	width: 41.66666666666667%
}

.mega-menu .drop-down .grid-col-6, .mega-menu .drop-down-tab-bar .grid-col-6 {
	width: 50%
}

.mega-menu .drop-down .grid-col-7, .mega-menu .drop-down-tab-bar .grid-col-7 {
	width: 58.33333333333333%
}

.mega-menu .drop-down .grid-col-8, .mega-menu .drop-down-tab-bar .grid-col-8 {
	width: 66.66666666666667%
}

.mega-menu .drop-down .grid-col-9, .mega-menu .drop-down-tab-bar .grid-col-9 {
	width: 75%
}

.mega-menu .drop-down .grid-col-10, .mega-menu .drop-down-tab-bar .grid-col-10 {
	width: 83.33333333333333%
}

.mega-menu .drop-down .grid-col-11, .mega-menu .drop-down-tab-bar .grid-col-11 {
	width: 91.66666666666667%
}

.mega-menu .drop-down .grid-col-12, .mega-menu .drop-down-tab-bar .grid-col-12 {
	width: 100%
}

.mega-menu .drop-down.grid-col-1, .mega-menu .drop-down-tab-bar.grid-col-1 {
	width: 8.333333333333333%
}

.mega-menu .drop-down.grid-col-2, .mega-menu .drop-down-tab-bar.grid-col-2 {
	width: 16.66666666666667%
}

.mega-menu .drop-down.grid-col-3, .mega-menu .drop-down-tab-bar.grid-col-3 {
	width: 25%
}

.mega-menu .drop-down.grid-col-4, .mega-menu .drop-down-tab-bar.grid-col-4 {
	width: 33.33333333333333%
}

.mega-menu .drop-down.grid-col-5, .mega-menu .drop-down-tab-bar.grid-col-5 {
	width: 41.66666666666667%
}

.mega-menu .drop-down.grid-col-6, .mega-menu .drop-down-tab-bar.grid-col-6 {
	width: 50%
}

.mega-menu .drop-down.grid-col-7, .mega-menu .drop-down-tab-bar.grid-col-7 {
	width: 58.33333333333333%
}

.mega-menu .drop-down.grid-col-8, .mega-menu .drop-down-tab-bar.grid-col-8 {
	width: 66.66666666666667%
}

.mega-menu .drop-down.grid-col-9, .mega-menu .drop-down-tab-bar.grid-col-9 {
	width: 75%
}

.mega-menu .drop-down.grid-col-10, .mega-menu .drop-down-tab-bar.grid-col-10 {
	width: 83.33333333333333%
}

.mega-menu .drop-down.grid-col-11, .mega-menu .drop-down-tab-bar.grid-col-11 {
	width: 91.66666666666667%
}

.mega-menu .drop-down.grid-col-12, .mega-menu .drop-down-tab-bar.grid-col-12 {
	width: 100%;
	left: 0
}

@media screen and (max-width:992px) {
	.mega-menu .drop-down [class*="grid-col-"], .mega-menu .drop-down-tab-bar [class*="grid-col-"] {
		width: 50%
	}

}

@media screen and (max-width:1023px) {
	.mega-menu .drop-down [class*="grid-col-"], .mega-menu .drop-down-tab-bar [class*="grid-col-"] {
		width: 100%
	}

}

.mega-menu .drop-down.grid-demo span, .mega-menu .drop-down-tab-bar.grid-demo span {
	float: left;
	display: inline-block;
	width: 100%;
	padding: 4px 6px;
	background: #bfbfbf;
	font-size: 0.75em;
	color: #555
}

.mega-menu .drop-down .space-0, .mega-menu .drop-down-tab-bar .space-0 {
	padding: 0 !important;
	margin: 0 !important
}

.mega-menu .drop-down a, .mega-menu .drop-down-tab-bar a {
	font-size: 0.8125em;
	display: inline-block;
	padding: 8px 0;
	width: 100%;
	max-width: 100%
}

.mega-menu .drop-down h1, .mega-menu .drop-down h2, .mega-menu .drop-down h3, .mega-menu .drop-down h4, .mega-menu .drop-down h5, .mega-menu .drop-down h6, .mega-menu .drop-down-tab-bar h1, .mega-menu .drop-down-tab-bar h2, .mega-menu .drop-down-tab-bar h3, .mega-menu .drop-down-tab-bar h4, .mega-menu .drop-down-tab-bar h5, .mega-menu .drop-down-tab-bar h6 {
	margin-top: 0;
	font-weight: 700
}

.mega-menu .drop-down h1, .mega-menu .drop-down-tab-bar h1 {
	font-size: 1.5em;
	line-height: 1;
	padding-top: 0.53em;
	margin-bottom: 0.5em
}

.mega-menu .drop-down h2, .mega-menu .drop-down-tab-bar h2 {
	font-size: 1.375em;
	padding-top: 0.25em;
	margin-bottom: 0.5em
}

.mega-menu .drop-down h3, .mega-menu .drop-down-tab-bar h3 {
	font-size: 1.125em;
	line-height: 1;
	padding-top: 0.35em;
	margin-bottom: 0.65em
}

.mega-menu .drop-down h4, .mega-menu .drop-down-tab-bar h4 {
	font-size: 1em;
	line-height: 1.25;
	padding-top: 0.45em;
	margin-bottom: 0.8em
}

.mega-menu .drop-down h5, .mega-menu .drop-down-tab-bar h5 {
	font-size: 0.875em;
	font-weight: bold;
	padding-top: 0.6em;
	margin-bottom: 0.9em
}

.mega-menu .drop-down h6, .mega-menu .drop-down-tab-bar h6 {
	font-size: 0.75em;
	font-weight: bold;
	margin-bottom: 0
}

.mega-menu .drop-down figure img, .mega-menu .drop-down-tab-bar figure img {
	width: 100%;
	height: auto;
	display: block
}

.mega-menu .drop-down figcaption, .mega-menu .drop-down-tab-bar figcaption {
	font-size: small;
	font-style: italic;
	color: #666666
}

.mega-menu .drop-down ul, .mega-menu .drop-down ol, .mega-menu .drop-down dl, .mega-menu .drop-down-tab-bar ul, .mega-menu .drop-down-tab-bar ol, .mega-menu .drop-down-tab-bar dl {
	padding: 0;
	margin: 0 0 14px
}

.mega-menu .drop-down form, .mega-menu .drop-down-tab-bar form {
	margin-bottom: 1.5em
}

.mega-menu .drop-down form ul, .mega-menu .drop-down-tab-bar form ul {
	list-style: none none;
	margin: 0;
	padding: 0
}

.mega-menu .drop-down form ul li, .mega-menu .drop-down-tab-bar form ul li {
	*zoom: 1;
	margin-bottom: 1.5em
}

.mega-menu .drop-down form ul li:before, .mega-menu .drop-down form ul li:after, .mega-menu .drop-down-tab-bar form ul li:before, .mega-menu .drop-down-tab-bar form ul li:after {
	content: "";
	display: table
}

.mega-menu .drop-down form ul li:after, .mega-menu .drop-down-tab-bar form ul li:after {
	clear: both
}

.mega-menu .drop-down fieldset, .mega-menu .drop-down-tab-bar fieldset {
	margin: 0;
	padding: 1.5em
}

.mega-menu .drop-down label, .mega-menu .drop-down-tab-bar label {
	display: block
}

.mega-menu .drop-down label.inline, .mega-menu .drop-down-tab-bar label.inline {
	display: inline;
	padding-right: 24px
}

.mega-menu .drop-down input[type="text"], .mega-menu .drop-down input[type="url"], .mega-menu .drop-down input[type="email"], .mega-menu .drop-down input[type="password"], .mega-menu .drop-down input[type="search"], .mega-menu .drop-down input[type="number"], .mega-menu .drop-down input[type="date"], .mega-menu .drop-down input[type="month"], .mega-menu .drop-down input[type="week"], .mega-menu .drop-down input[type="time"], .mega-menu .drop-down input[type="datetime"], .mega-menu .drop-down input[type="datetime-local"], .mega-menu .drop-down input[type="tel"], .mega-menu .drop-down textarea, .mega-menu .drop-down-tab-bar input[type="text"], .mega-menu .drop-down-tab-bar input[type="url"], .mega-menu .drop-down-tab-bar input[type="email"], .mega-menu .drop-down-tab-bar input[type="password"], .mega-menu .drop-down-tab-bar input[type="search"], .mega-menu .drop-down-tab-bar input[type="number"], .mega-menu .drop-down-tab-bar input[type="date"], .mega-menu .drop-down-tab-bar input[type="month"], .mega-menu .drop-down-tab-bar input[type="week"], .mega-menu .drop-down-tab-bar input[type="time"], .mega-menu .drop-down-tab-bar input[type="datetime"], .mega-menu .drop-down-tab-bar input[type="datetime-local"], .mega-menu .drop-down-tab-bar input[type="tel"], .mega-menu .drop-down-tab-bar textarea {
	display: block;
	width: 100%;
	margin: 0 0 0.75em;
	padding: 10px;
	font-size: 0.8125em;
	border: 1px solid #e8e8e8;
	line-height: 1.5em;
	font-family: "Open Sans", sans-serif
}

.mega-menu .drop-down select, .mega-menu .drop-down-tab-bar select {
	width: 100%;
	height: 2.1em;
	margin-bottom: 0.9em;
	border: 1px solid #cccccc;
	font-family: "Open Sans", sans-serif
}

.mega-menu .drop-down input[type="range"], .mega-menu .drop-down input[type="color"], .mega-menu .drop-down-tab-bar input[type="range"], .mega-menu .drop-down-tab-bar input[type="color"] {
	vertical-align: middle;
	height: 1.5em;
	width: 100%;
	font-family: "Open Sans", sans-serif
}

.mega-menu .drop-down input[type="range"], .mega-menu .drop-down-tab-bar input[type="range"] {
	height: 1.4em
}

.mega-menu .drop-down input[type="color"], .mega-menu .drop-down-tab-bar input[type="color"] {
	width: 1.5em;
	font-family: "Open Sans", sans-serif
}

.mega-menu .drop-down input[type="time"], .mega-menu .drop-down-tab-bar input[type="time"] {
	margin: 0 0 0.55em
}

.mega-menu .drop-down progress, .mega-menu .drop-down meter, .mega-menu .drop-down-tab-bar progress, .mega-menu .drop-down-tab-bar meter {
	display: block;
	width: 100%;
	height: 1.5em
}

.mega-menu .drop-down table, .mega-menu .drop-down-tab-bar table {
	margin-bottom: 1.4em;
	width: 100%;
	border: 1px solid #cccccc
}

.mega-menu .drop-down thead, .mega-menu .drop-down-tab-bar thead {
	text-align: left;
	font-weight: bold
}

.mega-menu .drop-down tbody tr:nth-child(even) td, .mega-menu .drop-down-tab-bar tbody tr:nth-child(even) td {
	background: #dddddd
}

.mega-menu .drop-down tfoot, .mega-menu .drop-down-tab-bar tfoot {
	font-style: italic
}

.mega-menu .drop-down tfoot td, .mega-menu .drop-down tfoot th, .mega-menu .drop-down-tab-bar tfoot td, .mega-menu .drop-down-tab-bar tfoot th {
	padding: .75em 10px
}

.mega-menu .drop-down th, .mega-menu .drop-down td, .mega-menu .drop-down caption, .mega-menu .drop-down-tab-bar th, .mega-menu .drop-down-tab-bar td, .mega-menu .drop-down-tab-bar caption {
	border: 1px solid #cccccc
}

.mega-menu .drop-down td, .mega-menu .drop-down th, .mega-menu .drop-down-tab-bar td, .mega-menu .drop-down-tab-bar th {
	padding: 0 10px 0 10px;
	line-height: 1.45em
}

.mega-menu .drop-down caption, .mega-menu .drop-down-tab-bar caption {
	border-bottom: 0;
	padding: .75em 10px;
	line-height: 1.45em;
	text-align: left;
	font-style: italic
}

.mega-menu .drop-down p, .mega-menu .drop-down-tab-bar p {
	margin: 0 0 1.5em 0;
	font-size: 0.8125em
}

.mega-menu .drop-down blockquote, .mega-menu .drop-down-tab-bar blockquote {
	margin: 0 1.5em 1.5em;
	font-style: italic
}

.mega-menu .drop-down mark, .mega-menu .drop-down-tab-bar mark {
	line-height: 1.5;
	background: #78aace;
	color: #ffffff
}

.mega-menu .drop-down del, .mega-menu .drop-down-tab-bar del {
	color: #dddddd
}

.mega-menu .drop-down code, .mega-menu .drop-down kbd, .mega-menu .drop-down pre, .mega-menu .drop-down samp, .mega-menu .drop-down-tab-bar code, .mega-menu .drop-down-tab-bar kbd, .mega-menu .drop-down-tab-bar pre, .mega-menu .drop-down-tab-bar samp {
	font-family: "Open Sans", sans-serif
}

.mega-menu .drop-down ins, .mega-menu .drop-down small, .mega-menu .drop-down-tab-bar ins, .mega-menu .drop-down-tab-bar small {
	line-height: 1.5
}

.mega-menu .drop-down kbd, .mega-menu .drop-down samp, .mega-menu .drop-down-tab-bar kbd, .mega-menu .drop-down-tab-bar samp {
	line-height: 1.4
}

.mega-menu .drop-down hr, .mega-menu .drop-down-tab-bar hr {
	background: #cccccc;
	color: #cccccc;
	clear: both;
	float: none;
	width: 100%;
	height: 1px;
	margin: 0 0 1.4em;
	border: none
}

.mega-menu .drop-down input[type="submit"], .mega-menu .drop-down input[type="button"], .mega-menu .drop-down button[type=submit], .mega-menu .drop-down button[type=reset], .mega-menu .drop-down-tab-bar input[type="submit"], .mega-menu .drop-down-tab-bar input[type="button"], .mega-menu .drop-down-tab-bar button[type=submit], .mega-menu .drop-down-tab-bar button[type=reset] {
	background: #ff6347;
	padding: 10px 20px;
	margin: 5px 10px 0 0;
	font-family: "Open Sans", sans-serif;
	line-height: 1.5em;
	font-weight: 600;
	font-size: 0.8125em;
	color: #fff;
	border-radius: 0;
	display: block;
	float: left;
	transition: background-color 200ms ease;
	border: none;
	text-align: center
}

.mega-menu .drop-down input[type="submit"]:hover, .mega-menu .drop-down input[type="button"]:hover, .mega-menu .drop-down button[type=submit]:hover, .mega-menu .drop-down button[type=reset]:hover, .mega-menu .drop-down-tab-bar input[type="submit"]:hover, .mega-menu .drop-down-tab-bar input[type="button"]:hover, .mega-menu .drop-down-tab-bar button[type=submit]:hover, .mega-menu .drop-down-tab-bar button[type=reset]:hover {
	background-color: #333
}

@media screen and (max-width:1023px) {
	.mega-menu .drop-down input[type="submit"], .mega-menu .drop-down input[type="button"], .mega-menu .drop-down button[type=submit], .mega-menu .drop-down button[type=reset], .mega-menu .drop-down-tab-bar input[type="submit"], .mega-menu .drop-down-tab-bar input[type="button"], .mega-menu .drop-down-tab-bar button[type=submit], .mega-menu .drop-down-tab-bar button[type=reset] {
		width: 100%
	}

}

.mega-menu .drop-down a, .mega-menu .drop-down-tab-bar a {
	transition: color 200ms ease
}

.mega-menu .drop-down a:hover, .mega-menu .drop-down-tab-bar a:hover {
	color: #ff6347
}

@media screen and (max-width:1023px) {
	.mega-menu .drop-down a, .mega-menu .drop-down-tab-bar a {
		width: auto
	}

}

.mega-menu .drop-down::-moz-selection, .mega-menu .drop-down-tab-bar::-moz-selection {
	background: #ffb9ad
}

.mega-menu .drop-down::selection, .mega-menu .drop-down-tab-bar::selection {
	background: #ffb9ad
}

.mega-menu .drop-down .list-description span, .mega-menu .drop-down-tab-bar .list-description span {
	color: #aeaeae;
	display: inline-block;
	width: 100%
}

.mega-menu .drop-down .image-description, .mega-menu .drop-down-tab-bar .image-description {
	position: relative
}

.mega-menu .drop-down .image-description img, .mega-menu .drop-down-tab-bar .image-description img {
	display: inline-block;
	float: left;
	max-width: 100%;
	position: absolute;
	left: 0;
	right: 0;
	height: 40px;
	width: 40px
}

.mega-menu .drop-down .image-description a, .mega-menu .drop-down-tab-bar .image-description a {
	padding-left: 50px
}

.mega-menu .drop-down .image-description span, .mega-menu .drop-down-tab-bar .image-description span {
	color: #aeaeae;
	display: inline-block;
	width: 100%
}

.mega-menu .drop-down i.fas, .mega-menu .drop-down-tab-bar i.fas {
	padding-right: 10px
}

.mega-menu .drop-down iframe, .mega-menu .drop-down-tab-bar iframe {
	width: 100%;
	display: block;
	margin: 0;
	padding: 0;
	border: none
}

@media screen and (min-width:1024px) {
	.mega-menu .drop-down.offset-1, .mega-menu .drop-down-tab-bar.offset-1 {
		margin-left: 100px
	}

	.mega-menu .drop-down.offset-2, .mega-menu .drop-down-tab-bar.offset-2 {
		margin-left: -150px
	}

	.mega-menu .drop-down.offset-3, .mega-menu .drop-down-tab-bar.offset-3 {
		margin-left: -200px
	}

	.mega-menu .drop-down.offset-4, .mega-menu .drop-down-tab-bar.offset-4 {
		margin-left: -250px
	}

	.mega-menu .drop-down.offset-5, .mega-menu .drop-down-tab-bar.offset-5 {
		margin-left: -300px
	}

}

.mega-menu .drop-down .menu-contact-form, .mega-menu .drop-down-tab-bar .menu-contact-form {
	margin: 0;
	display: block;
	float: left;
	width: 100%;
	background: #f7f7f7;
	padding: 20px
}

.mega-menu .drop-down .menu-contact-form input[type=submit], .mega-menu .drop-down .menu-contact-form input[type=reset], .mega-menu .drop-down-tab-bar .menu-contact-form input[type=submit], .mega-menu .drop-down-tab-bar .menu-contact-form input[type=reset] {
	text-align: center
}

.mega-menu .drop-down .menu-contact-form button i.fas, .mega-menu .drop-down-tab-bar .menu-contact-form button i.fas {
	display: none;
	color: #fff;
	line-height: normal;
	min-height: 1px;
	height: auto;
	margin: 0;
	padding: 0;
	position: relative;
	left: 0.625em
}

.mega-menu .drop-down .menu-contact-form .nav_form_notification, .mega-menu .drop-down-tab-bar .menu-contact-form .nav_form_notification {
	display: block;
	width: 100%;
	clear: both;
	font-size: 0.75em;
	padding: 0;
	margin: 0;
	position: relative;
	top: 0.625em;
	color: red
}

.mega-menu .mobileTriggerButton {
	margin: 0;
	padding: 0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 50px;
	display: none;
	z-index: -1
}

@media screen and (max-width:1023px) {
	.mega-menu .mobileTriggerButton {
		display: block
	}

}

.mega-menu .desktopTriggerButton {
	margin: 0;
	padding: 0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
	display: block;
	opacity: .2
}

@media screen and (max-width:1023px) {
	.mega-menu .desktopTriggerButton {
		display: none
	}

}

@media screen and (min-width:1024px) {
	.mega-menu.desktopTopFixed .menu-list-items {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		max-width: 100%;
		padding: 0 5%
	}

	.desktopTopFixed.mega-menu.vertical-left .menu-list-items {
		max-width: 250px
	}

	.desktopTopFixed.mega-menu.vertical-right .menu-list-items {
		max-width: 250px;
		left: auto
	}

	.mega-menu.desktopTopFixed .menu-list-items .drop-down.grid-col-12, .mega-menu.desktopTopFixed .menu-list-items .drop-down-tab-bar.grid-col-12 {
		width: 90%;
		margin: 0 5%
	}

}

@media screen and (max-width:1023px) {
	.mega-menu.mobileTopFixed .menu-list-items {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		max-width: 100%
	}

}

.mega-menu .drop-down-tab-bar {
	margin: 0;
	padding: 10px;
	float: left
}

.mega-menu .drop-down-tab-bar li {
	float: left;
	margin: 0;
	padding: 0;
	display: block
}

@media screen and (max-width:1023px) {
	.mega-menu .drop-down-tab-bar li {
		width: 100%;
		position: relative
	}

}

.mega-menu .drop-down-tab-bar a {
	float: left;
	width: 100%;
	display: inline-block;
	padding: 5px 10px;
	font-size: 0.8125em
}

@media screen and (max-width:1023px) {
	.mega-menu .drop-down-tab-bar a {
		width: auto;
		padding-right: 20px
	}

}

.mega-menu .drop-down-tab-bar i.fas {
	display: inline-block;
	padding-right: 5px
}

.mega-menu .drop-down-tab-bar i.fa.fa-indicator {
	padding-right: 0;
	padding-left: 10px;
	line-height: 0.8125em
}

@media screen and (max-width:1023px) {
	.mega-menu .drop-down-tab-bar i.fa.fa-indicator {
		float: right;
		position: absolute;
		right: 12px;
		top: 0;
		bottom: 0;
		line-height: 25px
	}

}

@media screen and (min-width:1024px) {
	.mega-menu .menu-links li.hoverTrigger > .drop-down.effect-scale, .mega-menu .menu-links li.hoverTrigger > .drop-down-tab-bar.effect-scale, .mega-menu .menu-links li.hoverTrigger > .drop-down-multilevel.effect-scale, .mega-menu .menu-links li.ClickTrigger .drop-down.effect-scale, .mega-menu .menu-links li.ClickTrigger .drop-down-tab-bar.effect-scale, .mega-menu .menu-links li.ClickTrigger .drop-down-multilevel.effect-scale {
		-webkit-transform: scale(0.8);
		-ms-transform: scale(0.8);
		transform: scale(0.8)
	}

	.mega-menu .menu-links li.hoverTrigger > .drop-down.effect-expand-top, .mega-menu .menu-links li.hoverTrigger > .drop-down-tab-bar.effect-expand-top, .mega-menu .menu-links li.hoverTrigger > .drop-down-multilevel.effect-expand-top, .mega-menu .menu-links li.ClickTrigger .drop-down.effect-expand-top, .mega-menu .menu-links li.ClickTrigger .drop-down-tab-bar.effect-expand-top, .mega-menu .menu-links li.ClickTrigger .drop-down-multilevel.effect-expand-top {
		-webkit-transform: rotateX(90deg);
		transform: rotateX(90deg);
		-webkit-transform-origin: 0 0;
		-ms-transform-origin: 0 0;
		transform-origin: 0 0
	}

	.mega-menu .menu-links li.hoverTrigger > .drop-down.effect-expand-bottom, .mega-menu .menu-links li.hoverTrigger > .drop-down-tab-bar.effect-expand-bottom, .mega-menu .menu-links li.hoverTrigger > .drop-down-multilevel.effect-expand-bottom, .mega-menu .menu-links li.ClickTrigger .drop-down.effect-expand-bottom, .mega-menu .menu-links li.ClickTrigger .drop-down-tab-bar.effect-expand-bottom, .mega-menu .menu-links li.ClickTrigger .drop-down-multilevel.effect-expand-bottom {
		-webkit-transform: rotateX(90deg);
		transform: rotateX(90deg);
		-webkit-transform-origin: 0 100%;
		-ms-transform-origin: 0 100%;
		transform-origin: 0 100%
	}

	.mega-menu .menu-links li.hoverTrigger > .drop-down.effect-expand-left, .mega-menu .menu-links li.hoverTrigger > .drop-down-tab-bar.effect-expand-left, .mega-menu .menu-links li.hoverTrigger > .drop-down-multilevel.effect-expand-left, .mega-menu .menu-links li.ClickTrigger .drop-down.effect-expand-left, .mega-menu .menu-links li.ClickTrigger .drop-down-tab-bar.effect-expand-left, .mega-menu .menu-links li.ClickTrigger .drop-down-multilevel.effect-expand-left {
		-webkit-transform: rotateY(90deg);
		transform: rotateY(90deg);
		-webkit-transform-origin: 0 0;
		-ms-transform-origin: 0 0;
		transform-origin: 0 0
	}

	.mega-menu .menu-links li.hoverTrigger > .drop-down.effect-expand-right, .mega-menu .menu-links li.hoverTrigger > .drop-down-tab-bar.effect-expand-right, .mega-menu .menu-links li.hoverTrigger > .drop-down-multilevel.effect-expand-right, .mega-menu .menu-links li.ClickTrigger .drop-down.effect-expand-right, .mega-menu .menu-links li.ClickTrigger .drop-down-tab-bar.effect-expand-right, .mega-menu .menu-links li.ClickTrigger .drop-down-multilevel.effect-expand-right {
		-webkit-transform: rotateY(90deg);
		transform: rotateY(90deg);
		-webkit-transform-origin: 100% 0;
		-ms-transform-origin: 100% 0;
		transform-origin: 100% 0
	}

	.mega-menu .menu-links li.hoverTrigger:hover > .drop-down, .mega-menu .menu-links li.hoverTrigger:hover > .drop-down-tab-bar, .mega-menu .menu-links li.hoverTrigger:hover > .drop-down-multilevel, .mega-menu .menu-links li.ClickTrigger .drop-down.active, .mega-menu .menu-links li.ClickTrigger .drop-down-tab-bar.active, .mega-menu .menu-links li.ClickTrigger .drop-down-multilevel.active {
		transition-delay: 200ms !important
	}

	.mega-menu .menu-links li.hoverTrigger:hover > .drop-down.effect-fade, .mega-menu .menu-links li.hoverTrigger:hover > .drop-down-tab-bar.effect-fade, .mega-menu .menu-links li.hoverTrigger:hover > .drop-down-multilevel.effect-fade, .mega-menu .menu-links li.ClickTrigger .drop-down.active.effect-fade, .mega-menu .menu-links li.ClickTrigger .drop-down-tab-bar.active.effect-fade, .mega-menu .menu-links li.ClickTrigger .drop-down-multilevel.active.effect-fade {
		opacity: 1;
		visibility: visible
	}

	.mega-menu .menu-links li.hoverTrigger:hover > .drop-down.effect-scale, .mega-menu .menu-links li.hoverTrigger:hover > .drop-down-tab-bar.effect-scale, .mega-menu .menu-links li.hoverTrigger:hover > .drop-down-multilevel.effect-scale, .mega-menu .menu-links li.ClickTrigger .drop-down.active.effect-scale, .mega-menu .menu-links li.ClickTrigger .drop-down-tab-bar.active.effect-scale, .mega-menu .menu-links li.ClickTrigger .drop-down-multilevel.active.effect-scale {
		opacity: 1;
		visibility: visible;
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1)
	}

	.mega-menu .menu-links li.hoverTrigger:hover > .drop-down.effect-expand-top, .mega-menu .menu-links li.hoverTrigger:hover > .drop-down-tab-bar.effect-expand-top, .mega-menu .menu-links li.hoverTrigger:hover > .drop-down-multilevel.effect-expand-top, .mega-menu .menu-links li.ClickTrigger .drop-down.active.effect-expand-top, .mega-menu .menu-links li.ClickTrigger .drop-down-tab-bar.active.effect-expand-top, .mega-menu .menu-links li.ClickTrigger .drop-down-multilevel.active.effect-expand-top {
		opacity: 1;
		visibility: visible;
		-webkit-transform: rotateX(0deg);
		transform: rotateX(0deg)
	}

	.mega-menu .menu-links li.hoverTrigger:hover > .drop-down.effect-expand-bottom, .mega-menu .menu-links li.hoverTrigger:hover > .drop-down-tab-bar.effect-expand-bottom, .mega-menu .menu-links li.hoverTrigger:hover > .drop-down-multilevel.effect-expand-bottom, .mega-menu .menu-links li.ClickTrigger .drop-down.active.effect-expand-bottom, .mega-menu .menu-links li.ClickTrigger .drop-down-tab-bar.active.effect-expand-bottom, .mega-menu .menu-links li.ClickTrigger .drop-down-multilevel.active.effect-expand-bottom {
		opacity: 1;
		visibility: visible;
		-webkit-transform: rotateX(0deg);
		transform: rotateX(0deg)
	}

	.mega-menu .menu-links li.hoverTrigger:hover > .drop-down.effect-expand-left, .mega-menu .menu-links li.hoverTrigger:hover > .drop-down-tab-bar.effect-expand-left, .mega-menu .menu-links li.hoverTrigger:hover > .drop-down-multilevel.effect-expand-left, .mega-menu .menu-links li.ClickTrigger .drop-down.active.effect-expand-left, .mega-menu .menu-links li.ClickTrigger .drop-down-tab-bar.active.effect-expand-left, .mega-menu .menu-links li.ClickTrigger .drop-down-multilevel.active.effect-expand-left {
		opacity: 1;
		visibility: visible;
		-webkit-transform: rotateY(0deg);
		transform: rotateY(0deg)
	}

	.mega-menu .menu-links li.hoverTrigger:hover > .drop-down.effect-expand-right, .mega-menu .menu-links li.hoverTrigger:hover > .drop-down-tab-bar.effect-expand-right, .mega-menu .menu-links li.hoverTrigger:hover > .drop-down-multilevel.effect-expand-right, .mega-menu .menu-links li.ClickTrigger .drop-down.active.effect-expand-right, .mega-menu .menu-links li.ClickTrigger .drop-down-tab-bar.active.effect-expand-right, .mega-menu .menu-links li.ClickTrigger .drop-down-multilevel.active.effect-expand-right {
		opacity: 1;
		visibility: visible;
		-webkit-transform: rotateY(0deg);
		transform: rotateY(0deg)
	}

}

@media screen and (max-width:1023px) {
	.mega-menu .menu-links li .drop-down, .mega-menu .menu-links li .drop-down-tab-bar, .mega-menu .menu-links li .drop-down-multilevel {
		transition: none !important
	}

}

@media screen and (min-width:1024px) {
	.mega-menu.vertical-left, .mega-menu.vertical-right {
		float: left;
		width: auto;
		display: block;
		max-width: 250px
	}

	.mega-menu.vertical-left .menu-logo, .mega-menu.vertical-right .menu-logo {
		clear: both;
		width: 100%
	}

	.mega-menu.vertical-left .menu-logo > li, .mega-menu.vertical-right .menu-logo > li {
		width: 100%
	}

	.mega-menu.vertical-left .menu-logo > li > a, .mega-menu.vertical-right .menu-logo > li > a {
		width: 100%
	}

	.mega-menu.vertical-left .menu-links, .mega-menu.vertical-right .menu-links {
		clear: both;
		width: 100%
	}

	.mega-menu.vertical-left .menu-links > li, .mega-menu.vertical-right .menu-links > li {
		clear: both;
		width: 100%;
		position: relative
	}

	.mega-menu.vertical-left .menu-links > li > a, .mega-menu.vertical-right .menu-links > li > a {
		width: 100%;
		position: relative;
		line-height: 48px
	}

	.mega-menu.vertical-left .menu-links > li > a i.fas.fa-indicator, .mega-menu.vertical-right .menu-links > li > a i.fas.fa-indicator {
		float: right;
		line-height: 48px
	}

	.mega-menu.vertical-left .menu-social-bar, .mega-menu.vertical-right .menu-social-bar {
		width: 100%;
		text-align: center
	}

	.mega-menu.vertical-left .menu-social-bar > li, .mega-menu.vertical-right .menu-social-bar > li {
		display: inline-block;
		float: none
	}

	.mega-menu.vertical-left .menu-social-bar > li > a, .mega-menu.vertical-right .menu-social-bar > li > a {
		padding-left: 10px;
		padding-right: 10px
	}

	.mega-menu.vertical-left .drop-down-multilevel, .mega-menu.vertical-right .drop-down-multilevel {
		top: 0;
		left: 100%
	}

	.mega-menu.vertical-left .drop-down, .mega-menu.vertical-left .drop-down-tab-bar, .mega-menu.vertical-right .drop-down, .mega-menu.vertical-right .drop-down-tab-bar {
		left: 100%;
		top: 0;
		min-width: 600px
	}

	.mega-menu.vertical-left .drop-down.grid-col-12, .mega-menu.vertical-left .drop-down-tab-bar.grid-col-12, .mega-menu.vertical-right .drop-down.grid-col-12, .mega-menu.vertical-right .drop-down-tab-bar.grid-col-12 {
		min-width: 1000px
	}

	.mega-menu.vertical-left .offset-1, .mega-menu.vertical-left .offset-2, .mega-menu.vertical-left .offset-3, .mega-menu.vertical-left .offset-4, .mega-menu.vertical-left .offset-5, .mega-menu.vertical-right .offset-1, .mega-menu.vertical-right .offset-2, .mega-menu.vertical-right .offset-3, .mega-menu.vertical-right .offset-4, .mega-menu.vertical-right .offset-5 {
		margin-left: 0
	}

	.mega-menu.vertical-left .offset-1-vertical, .mega-menu.vertical-right .offset-1-vertical {
		margin-top: -100px !important
	}

	.mega-menu.vertical-left .offset-2-vertical, .mega-menu.vertical-right .offset-2-vertical {
		margin-top: -150px !important
	}

	.mega-menu.vertical-left .offset-3-vertical, .mega-menu.vertical-right .offset-3-vertical {
		margin-top: -200px !important
	}

	.mega-menu.vertical-left .offset-4-vertical, .mega-menu.vertical-right .offset-4-vertical {
		margin-top: -250px !important
	}

	.mega-menu.vertical-left .offset-5-vertical, .mega-menu.vertical-right .offset-5-vertical {
		margin-top: -300px !important
	}

	.mega-menu.vertical-left.desktopTopFixed, .mega-menu.vertical-right.desktopTopFixed {
		float: left;
		right: auto;
		padding: 0;
		height: 100%
	}

	.mega-menu.vertical-left.desktopTopFixed .menu-list-items, .mega-menu.vertical-right.desktopTopFixed .menu-list-items {
		padding: 0;
		height: 100%
	}

	.mega-menu.vertical-left.desktopTopFixed .drop-down, .mega-menu.vertical-left.desktopTopFixed .drop-down-tab-bar, .mega-menu.vertical-right.desktopTopFixed .drop-down, .mega-menu.vertical-right.desktopTopFixed .drop-down-tab-bar {
		margin: 0
	}

	.mega-menu.vertical-right {
		float: right
	}

	.mega-menu.vertical-right .drop-down-multilevel {
		left: auto;
		right: 100%
	}

}

@media screen and (min-width:1024px) and (min-width:1024px) {
	.mega-menu.vertical-right .drop-down-multilevel.left-side {
		left: 100%
	}

}

@media screen and (min-width:1024px) {
	.mega-menu.vertical-right .drop-down, .mega-menu.vertical-right .drop-down-tab-bar {
		left: auto;
		right: 100%
	}

	.mega-menu.vertical-right.desktopTopFixed {
		float: right;
		left: auto;
		right: 0;
		padding: 0;
		height: 100%
	}

}

@media screen and (min-width:1024px) {
	.mega-menu.vertical-left .menu-search-bar, .mega-menu.vertical-right .menu-search-bar {
		width: 100%
	}

	.mega-menu.vertical-left .menu-search-bar input, .mega-menu.vertical-left .menu-search-bar li, .mega-menu.vertical-left .menu-search-bar form, .mega-menu.vertical-left .menu-search-bar label, .mega-menu.vertical-right .menu-search-bar input, .mega-menu.vertical-right .menu-search-bar li, .mega-menu.vertical-right .menu-search-bar form, .mega-menu.vertical-right .menu-search-bar label {
		width: 100%;
		max-width: 100%;
		background: #ff6347;
		transition: none
	}

	.mega-menu.vertical-left .menu-search-bar input, .mega-menu.vertical-right .menu-search-bar input {
		padding-left: 20px
	}

}

@media screen and (min-width:1024px) {
	.mega-menu.menuFullWidth {
		max-width: 100%
	}

}

.mega-menu[data-color='blue-grey'] {
}

.mega-menu[data-color='blue-grey'] > section.menu-list-items {
	background-color: #333
}

.mega-menu[data-color='blue-grey'] .menu-logo > li > a:hover {
	background-color: #607d8b
}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='blue-grey'] .menu-links > li.activeTriggerMobile {
		background-color: #607d8b
	}

}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='blue-grey'] .menu-links > li.activeTrigger {
		background-color: #607d8b
	}

}

.mega-menu[data-color='blue-grey'] .menu-links > li.active {
	background-color: #607d8b
}

.mega-menu[data-color='blue-grey'] .menu-links > li:hover {
	background-color: #607d8b
}

.mega-menu[data-color='blue-grey'] .menu-search-bar li:hover i.fas.fa-search {
	background: #607d8b
}

.mega-menu[data-color='blue-grey'] .menu-search-bar input:focus {
	background: #607d8b
}

.mega-menu[data-color='blue-grey'] .menu-mobile-collapse-trigger {
	background: #1a1a1a
}

.mega-menu[data-color='blue-grey'] .menu-mobile-collapse-trigger:hover {
	background: black
}

.mega-menu[data-color='blue-grey'] .drop-down-multilevel li:hover {
	background: #607d8b
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='blue-grey'] .drop-down-multilevel li.activeTrigger {
		background: #607d8b
	}

}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='blue-grey'] .drop-down-multilevel li.activeTriggerMobile {
		background: #607d8b
	}

}

.mega-menu[data-color='blue-grey'] .drop-down input[type="submit"], .mega-menu[data-color='blue-grey'] .drop-down input[type="button"], .mega-menu[data-color='blue-grey'] .drop-down button[type=submit], .mega-menu[data-color='blue-grey'] .drop-down button[type=reset], .mega-menu[data-color='blue-grey'] .drop-down-tab-bar input[type="submit"], .mega-menu[data-color='blue-grey'] .drop-down-tab-bar input[type="button"], .mega-menu[data-color='blue-grey'] .drop-down-tab-bar button[type=submit], .mega-menu[data-color='blue-grey'] .drop-down-tab-bar button[type=reset] {
	background: #607d8b
}

.mega-menu[data-color='blue-grey'] .drop-down input[type="submit"]:hover, .mega-menu[data-color='blue-grey'] .drop-down input[type="button"]:hover, .mega-menu[data-color='blue-grey'] .drop-down button[type=submit]:hover, .mega-menu[data-color='blue-grey'] .drop-down button[type=reset]:hover, .mega-menu[data-color='blue-grey'] .drop-down-tab-bar input[type="submit"]:hover, .mega-menu[data-color='blue-grey'] .drop-down-tab-bar input[type="button"]:hover, .mega-menu[data-color='blue-grey'] .drop-down-tab-bar button[type=submit]:hover, .mega-menu[data-color='blue-grey'] .drop-down-tab-bar button[type=reset]:hover {
	background-color: #333
}

.mega-menu[data-color='blue-grey'] .drop-down a:hover, .mega-menu[data-color='blue-grey'] .drop-down-tab-bar a:hover {
	color: #607d8b
}

.mega-menu[data-color='blue-grey'] .drop-down::-moz-selection, .mega-menu[data-color='blue-grey'] .drop-down-tab-bar::-moz-selection {
	background: #99aeb8
}

.mega-menu[data-color='blue-grey'] .drop-down::selection, .mega-menu[data-color='blue-grey'] .drop-down-tab-bar::selection {
	background: #99aeb8
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='blue-grey'].vertical-left .menu-search-bar input, .mega-menu[data-color='blue-grey'].vertical-left .menu-search-bar li, .mega-menu[data-color='blue-grey'].vertical-left .menu-search-bar form, .mega-menu[data-color='blue-grey'].vertical-left .menu-search-bar label, .mega-menu[data-color='blue-grey'].vertical-right .menu-search-bar input, .mega-menu[data-color='blue-grey'].vertical-right .menu-search-bar li, .mega-menu[data-color='blue-grey'].vertical-right .menu-search-bar form, .mega-menu[data-color='blue-grey'].vertical-right .menu-search-bar label {
		background: #607d8b
	}

}

.mega-menu[data-color='blue-grey-invert'] {
}

.mega-menu[data-color='blue-grey-invert'] > section.menu-list-items {
	background-color: #607d8b
}

.mega-menu[data-color='blue-grey-invert'] .menu-logo > li > a:hover {
	background-color: #566f7c
}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='blue-grey-invert'] .menu-links > li.activeTriggerMobile {
		background-color: #566f7c
	}

}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='blue-grey-invert'] .menu-links > li.activeTrigger {
		background-color: #566f7c
	}

}

.mega-menu[data-color='blue-grey-invert'] .menu-links > li.active {
	background-color: #566f7c
}

.mega-menu[data-color='blue-grey-invert'] .menu-links > li:hover {
	background-color: #566f7c
}

.mega-menu[data-color='blue-grey-invert'] .menu-search-bar li:hover i.fas.fa-search {
	background: #566f7c
}

.mega-menu[data-color='blue-grey-invert'] .menu-search-bar input:focus {
	background: #566f7c
}

.mega-menu[data-color='blue-grey-invert'] .menu-mobile-collapse-trigger {
	background: #4b626d
}

.mega-menu[data-color='blue-grey-invert'] .menu-mobile-collapse-trigger:hover {
	background: #36474f
}

.mega-menu[data-color='blue-grey-invert'] .drop-down-multilevel li:hover {
	background: #566f7c
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='blue-grey-invert'] .drop-down-multilevel li.activeTrigger {
		background: #566f7c
	}

}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='blue-grey-invert'] .drop-down-multilevel li.activeTriggerMobile {
		background: #566f7c
	}

}

.mega-menu[data-color='blue-grey-invert'] .drop-down input[type="submit"], .mega-menu[data-color='blue-grey-invert'] .drop-down input[type="button"], .mega-menu[data-color='blue-grey-invert'] .drop-down button[type=submit], .mega-menu[data-color='blue-grey-invert'] .drop-down button[type=reset], .mega-menu[data-color='blue-grey-invert'] .drop-down-tab-bar input[type="submit"], .mega-menu[data-color='blue-grey-invert'] .drop-down-tab-bar input[type="button"], .mega-menu[data-color='blue-grey-invert'] .drop-down-tab-bar button[type=submit], .mega-menu[data-color='blue-grey-invert'] .drop-down-tab-bar button[type=reset] {
	background: #566f7c
}

.mega-menu[data-color='blue-grey-invert'] .drop-down input[type="submit"]:hover, .mega-menu[data-color='blue-grey-invert'] .drop-down input[type="button"]:hover, .mega-menu[data-color='blue-grey-invert'] .drop-down button[type=submit]:hover, .mega-menu[data-color='blue-grey-invert'] .drop-down button[type=reset]:hover, .mega-menu[data-color='blue-grey-invert'] .drop-down-tab-bar input[type="submit"]:hover, .mega-menu[data-color='blue-grey-invert'] .drop-down-tab-bar input[type="button"]:hover, .mega-menu[data-color='blue-grey-invert'] .drop-down-tab-bar button[type=submit]:hover, .mega-menu[data-color='blue-grey-invert'] .drop-down-tab-bar button[type=reset]:hover {
	background-color: #607d8b
}

.mega-menu[data-color='blue-grey-invert'] .drop-down a:hover, .mega-menu[data-color='blue-grey-invert'] .drop-down-tab-bar a:hover {
	color: #566f7c
}

.mega-menu[data-color='blue-grey-invert'] .drop-down::-moz-selection, .mega-menu[data-color='blue-grey-invert'] .drop-down-tab-bar::-moz-selection {
	background: #8aa2ae
}

.mega-menu[data-color='blue-grey-invert'] .drop-down::selection, .mega-menu[data-color='blue-grey-invert'] .drop-down-tab-bar::selection {
	background: #8aa2ae
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='blue-grey-invert'].vertical-left .menu-search-bar input, .mega-menu[data-color='blue-grey-invert'].vertical-left .menu-search-bar li, .mega-menu[data-color='blue-grey-invert'].vertical-left .menu-search-bar form, .mega-menu[data-color='blue-grey-invert'].vertical-left .menu-search-bar label, .mega-menu[data-color='blue-grey-invert'].vertical-right .menu-search-bar input, .mega-menu[data-color='blue-grey-invert'].vertical-right .menu-search-bar li, .mega-menu[data-color='blue-grey-invert'].vertical-right .menu-search-bar form, .mega-menu[data-color='blue-grey-invert'].vertical-right .menu-search-bar label {
		background: #566f7c
	}

}

.mega-menu[data-color='brown'] {
}

.mega-menu[data-color='brown'] > section.menu-list-items {
	background-color: #333
}

.mega-menu[data-color='brown'] .menu-logo > li > a:hover {
	background-color: #795547
}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='brown'] .menu-links > li.activeTriggerMobile {
		background-color: #795547
	}

}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='brown'] .menu-links > li.activeTrigger {
		background-color: #795547
	}

}

.mega-menu[data-color='brown'] .menu-links > li.active {
	background-color: #795547
}

.mega-menu[data-color='brown'] .menu-links > li:hover {
	background-color: #795547
}

.mega-menu[data-color='brown'] .menu-search-bar li:hover i.fas.fa-search {
	background: #795547
}

.mega-menu[data-color='brown'] .menu-search-bar input:focus {
	background: #795547
}

.mega-menu[data-color='brown'] .menu-mobile-collapse-trigger {
	background: #1a1a1a
}

.mega-menu[data-color='brown'] .menu-mobile-collapse-trigger:hover {
	background: black
}

.mega-menu[data-color='brown'] .drop-down-multilevel li:hover {
	background: #795547
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='brown'] .drop-down-multilevel li.activeTrigger {
		background: #795547
	}

}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='brown'] .drop-down-multilevel li.activeTriggerMobile {
		background: #795547
	}

}

.mega-menu[data-color='brown'] .drop-down input[type="submit"], .mega-menu[data-color='brown'] .drop-down input[type="button"], .mega-menu[data-color='brown'] .drop-down button[type=submit], .mega-menu[data-color='brown'] .drop-down button[type=reset], .mega-menu[data-color='brown'] .drop-down-tab-bar input[type="submit"], .mega-menu[data-color='brown'] .drop-down-tab-bar input[type="button"], .mega-menu[data-color='brown'] .drop-down-tab-bar button[type=submit], .mega-menu[data-color='brown'] .drop-down-tab-bar button[type=reset] {
	background: #795547
}

.mega-menu[data-color='brown'] .drop-down input[type="submit"]:hover, .mega-menu[data-color='brown'] .drop-down input[type="button"]:hover, .mega-menu[data-color='brown'] .drop-down button[type=submit]:hover, .mega-menu[data-color='brown'] .drop-down button[type=reset]:hover, .mega-menu[data-color='brown'] .drop-down-tab-bar input[type="submit"]:hover, .mega-menu[data-color='brown'] .drop-down-tab-bar input[type="button"]:hover, .mega-menu[data-color='brown'] .drop-down-tab-bar button[type=submit]:hover, .mega-menu[data-color='brown'] .drop-down-tab-bar button[type=reset]:hover {
	background-color: #333
}

.mega-menu[data-color='brown'] .drop-down a:hover, .mega-menu[data-color='brown'] .drop-down-tab-bar a:hover {
	color: #795547
}

.mega-menu[data-color='brown'] .drop-down::-moz-selection, .mega-menu[data-color='brown'] .drop-down-tab-bar::-moz-selection {
	background: #af8777
}

.mega-menu[data-color='brown'] .drop-down::selection, .mega-menu[data-color='brown'] .drop-down-tab-bar::selection {
	background: #af8777
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='brown'].vertical-left .menu-search-bar input, .mega-menu[data-color='brown'].vertical-left .menu-search-bar li, .mega-menu[data-color='brown'].vertical-left .menu-search-bar form, .mega-menu[data-color='brown'].vertical-left .menu-search-bar label, .mega-menu[data-color='brown'].vertical-right .menu-search-bar input, .mega-menu[data-color='brown'].vertical-right .menu-search-bar li, .mega-menu[data-color='brown'].vertical-right .menu-search-bar form, .mega-menu[data-color='brown'].vertical-right .menu-search-bar label {
		background: #795547
	}

}

.mega-menu[data-color='brown-invert'] {
}

.mega-menu[data-color='brown-invert'] > section.menu-list-items {
	background-color: #896050
}

.mega-menu[data-color='brown-invert'] .menu-logo > li > a:hover {
	background-color: #694a3e
}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='brown-invert'] .menu-links > li.activeTriggerMobile {
		background-color: #694a3e
	}

}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='brown-invert'] .menu-links > li.activeTrigger {
		background-color: #694a3e
	}

}

.mega-menu[data-color='brown-invert'] .menu-links > li.active {
	background-color: #694a3e
}

.mega-menu[data-color='brown-invert'] .menu-links > li:hover {
	background-color: #694a3e
}

.mega-menu[data-color='brown-invert'] .menu-search-bar li:hover i.fas.fa-search {
	background: #694a3e
}

.mega-menu[data-color='brown-invert'] .menu-search-bar input:focus {
	background: #694a3e
}

.mega-menu[data-color='brown-invert'] .menu-mobile-collapse-trigger {
	background: #694a3e
}

.mega-menu[data-color='brown-invert'] .menu-mobile-collapse-trigger:hover {
	background: #49332b
}

.mega-menu[data-color='brown-invert'] .drop-down-multilevel li:hover {
	background: #694a3e
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='brown-invert'] .drop-down-multilevel li.activeTrigger {
		background: #694a3e
	}

}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='brown-invert'] .drop-down-multilevel li.activeTriggerMobile {
		background: #694a3e
	}

}

.mega-menu[data-color='brown-invert'] .drop-down input[type="submit"], .mega-menu[data-color='brown-invert'] .drop-down input[type="button"], .mega-menu[data-color='brown-invert'] .drop-down button[type=submit], .mega-menu[data-color='brown-invert'] .drop-down button[type=reset], .mega-menu[data-color='brown-invert'] .drop-down-tab-bar input[type="submit"], .mega-menu[data-color='brown-invert'] .drop-down-tab-bar input[type="button"], .mega-menu[data-color='brown-invert'] .drop-down-tab-bar button[type=submit], .mega-menu[data-color='brown-invert'] .drop-down-tab-bar button[type=reset] {
	background: #694a3e
}

.mega-menu[data-color='brown-invert'] .drop-down input[type="submit"]:hover, .mega-menu[data-color='brown-invert'] .drop-down input[type="button"]:hover, .mega-menu[data-color='brown-invert'] .drop-down button[type=submit]:hover, .mega-menu[data-color='brown-invert'] .drop-down button[type=reset]:hover, .mega-menu[data-color='brown-invert'] .drop-down-tab-bar input[type="submit"]:hover, .mega-menu[data-color='brown-invert'] .drop-down-tab-bar input[type="button"]:hover, .mega-menu[data-color='brown-invert'] .drop-down-tab-bar button[type=submit]:hover, .mega-menu[data-color='brown-invert'] .drop-down-tab-bar button[type=reset]:hover {
	background-color: #896050
}

.mega-menu[data-color='brown-invert'] .drop-down a:hover, .mega-menu[data-color='brown-invert'] .drop-down-tab-bar a:hover {
	color: #694a3e
}

.mega-menu[data-color='brown-invert'] .drop-down::-moz-selection, .mega-menu[data-color='brown-invert'] .drop-down-tab-bar::-moz-selection {
	background: #a67867
}

.mega-menu[data-color='brown-invert'] .drop-down::selection, .mega-menu[data-color='brown-invert'] .drop-down-tab-bar::selection {
	background: #a67867
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='brown-invert'].vertical-left .menu-search-bar input, .mega-menu[data-color='brown-invert'].vertical-left .menu-search-bar li, .mega-menu[data-color='brown-invert'].vertical-left .menu-search-bar form, .mega-menu[data-color='brown-invert'].vertical-left .menu-search-bar label, .mega-menu[data-color='brown-invert'].vertical-right .menu-search-bar input, .mega-menu[data-color='brown-invert'].vertical-right .menu-search-bar li, .mega-menu[data-color='brown-invert'].vertical-right .menu-search-bar form, .mega-menu[data-color='brown-invert'].vertical-right .menu-search-bar label {
		background: #694a3e
	}

}

.mega-menu[data-color='cyan'] {
}

.mega-menu[data-color='cyan'] > section.menu-list-items {
	background-color: #333
}

.mega-menu[data-color='cyan'] .menu-logo > li > a:hover {
	background-color: #00bcd5
}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='cyan'] .menu-links > li.activeTriggerMobile {
		background-color: #00bcd5
	}

}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='cyan'] .menu-links > li.activeTrigger {
		background-color: #00bcd5
	}

}

.mega-menu[data-color='cyan'] .menu-links > li.active {
	background-color: #00bcd5
}

.mega-menu[data-color='cyan'] .menu-links > li:hover {
	background-color: #00bcd5
}

.mega-menu[data-color='cyan'] .menu-search-bar li:hover i.fas.fa-search {
	background: #00bcd5
}

.mega-menu[data-color='cyan'] .menu-search-bar input:focus {
	background: #00bcd5
}

.mega-menu[data-color='cyan'] .menu-mobile-collapse-trigger {
	background: #1a1a1a
}

.mega-menu[data-color='cyan'] .menu-mobile-collapse-trigger:hover {
	background: black
}

.mega-menu[data-color='cyan'] .drop-down-multilevel li:hover {
	background: #00bcd5
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='cyan'] .drop-down-multilevel li.activeTrigger {
		background: #00bcd5
	}

}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='cyan'] .drop-down-multilevel li.activeTriggerMobile {
		background: #00bcd5
	}

}

.mega-menu[data-color='cyan'] .drop-down input[type="submit"], .mega-menu[data-color='cyan'] .drop-down input[type="button"], .mega-menu[data-color='cyan'] .drop-down button[type=submit], .mega-menu[data-color='cyan'] .drop-down button[type=reset], .mega-menu[data-color='cyan'] .drop-down-tab-bar input[type="submit"], .mega-menu[data-color='cyan'] .drop-down-tab-bar input[type="button"], .mega-menu[data-color='cyan'] .drop-down-tab-bar button[type=submit], .mega-menu[data-color='cyan'] .drop-down-tab-bar button[type=reset] {
	background: #00bcd5
}

.mega-menu[data-color='cyan'] .drop-down input[type="submit"]:hover, .mega-menu[data-color='cyan'] .drop-down input[type="button"]:hover, .mega-menu[data-color='cyan'] .drop-down button[type=submit]:hover, .mega-menu[data-color='cyan'] .drop-down button[type=reset]:hover, .mega-menu[data-color='cyan'] .drop-down-tab-bar input[type="submit"]:hover, .mega-menu[data-color='cyan'] .drop-down-tab-bar input[type="button"]:hover, .mega-menu[data-color='cyan'] .drop-down-tab-bar button[type=submit]:hover, .mega-menu[data-color='cyan'] .drop-down-tab-bar button[type=reset]:hover {
	background-color: #333
}

.mega-menu[data-color='cyan'] .drop-down a:hover, .mega-menu[data-color='cyan'] .drop-down-tab-bar a:hover {
	color: #00bcd5
}

.mega-menu[data-color='cyan'] .drop-down::-moz-selection, .mega-menu[data-color='cyan'] .drop-down-tab-bar::-moz-selection {
	background: #3ce8ff
}

.mega-menu[data-color='cyan'] .drop-down::selection, .mega-menu[data-color='cyan'] .drop-down-tab-bar::selection {
	background: #3ce8ff
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='cyan'].vertical-left .menu-search-bar input, .mega-menu[data-color='cyan'].vertical-left .menu-search-bar li, .mega-menu[data-color='cyan'].vertical-left .menu-search-bar form, .mega-menu[data-color='cyan'].vertical-left .menu-search-bar label, .mega-menu[data-color='cyan'].vertical-right .menu-search-bar input, .mega-menu[data-color='cyan'].vertical-right .menu-search-bar li, .mega-menu[data-color='cyan'].vertical-right .menu-search-bar form, .mega-menu[data-color='cyan'].vertical-right .menu-search-bar label {
		background: #00bcd5
	}

}

.mega-menu[data-color='cyan-invert'] {
}

.mega-menu[data-color='cyan-invert'] > section.menu-list-items {
	background-color: #00bcd5
}

.mega-menu[data-color='cyan-invert'] .menu-logo > li > a:hover {
	background-color: #00a5bc
}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='cyan-invert'] .menu-links > li.activeTriggerMobile {
		background-color: #00a5bc
	}

}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='cyan-invert'] .menu-links > li.activeTrigger {
		background-color: #00a5bc
	}

}

.mega-menu[data-color='cyan-invert'] .menu-links > li.active {
	background-color: #00a5bc
}

.mega-menu[data-color='cyan-invert'] .menu-links > li:hover {
	background-color: #00a5bc
}

.mega-menu[data-color='cyan-invert'] .menu-search-bar li:hover i.fas.fa-search {
	background: #00a5bc
}

.mega-menu[data-color='cyan-invert'] .menu-search-bar input:focus {
	background: #00a5bc
}

.mega-menu[data-color='cyan-invert'] .menu-mobile-collapse-trigger {
	background: #008fa2
}

.mega-menu[data-color='cyan-invert'] .menu-mobile-collapse-trigger:hover {
	background: #00626f
}

.mega-menu[data-color='cyan-invert'] .drop-down-multilevel li:hover {
	background: #00a5bc
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='cyan-invert'] .drop-down-multilevel li.activeTrigger {
		background: #00a5bc
	}

}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='cyan-invert'] .drop-down-multilevel li.activeTriggerMobile {
		background: #00a5bc
	}

}

.mega-menu[data-color='cyan-invert'] .drop-down input[type="submit"], .mega-menu[data-color='cyan-invert'] .drop-down input[type="button"], .mega-menu[data-color='cyan-invert'] .drop-down button[type=submit], .mega-menu[data-color='cyan-invert'] .drop-down button[type=reset], .mega-menu[data-color='cyan-invert'] .drop-down-tab-bar input[type="submit"], .mega-menu[data-color='cyan-invert'] .drop-down-tab-bar input[type="button"], .mega-menu[data-color='cyan-invert'] .drop-down-tab-bar button[type=submit], .mega-menu[data-color='cyan-invert'] .drop-down-tab-bar button[type=reset] {
	background: #00a5bc
}

.mega-menu[data-color='cyan-invert'] .drop-down input[type="submit"]:hover, .mega-menu[data-color='cyan-invert'] .drop-down input[type="button"]:hover, .mega-menu[data-color='cyan-invert'] .drop-down button[type=submit]:hover, .mega-menu[data-color='cyan-invert'] .drop-down button[type=reset]:hover, .mega-menu[data-color='cyan-invert'] .drop-down-tab-bar input[type="submit"]:hover, .mega-menu[data-color='cyan-invert'] .drop-down-tab-bar input[type="button"]:hover, .mega-menu[data-color='cyan-invert'] .drop-down-tab-bar button[type=submit]:hover, .mega-menu[data-color='cyan-invert'] .drop-down-tab-bar button[type=reset]:hover {
	background-color: #00bcd5
}

.mega-menu[data-color='cyan-invert'] .drop-down a:hover, .mega-menu[data-color='cyan-invert'] .drop-down-tab-bar a:hover {
	color: #00a5bc
}

.mega-menu[data-color='cyan-invert'] .drop-down::-moz-selection, .mega-menu[data-color='cyan-invert'] .drop-down-tab-bar::-moz-selection {
	background: #22e5ff
}

.mega-menu[data-color='cyan-invert'] .drop-down::selection, .mega-menu[data-color='cyan-invert'] .drop-down-tab-bar::selection {
	background: #22e5ff
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='cyan-invert'].vertical-left .menu-search-bar input, .mega-menu[data-color='cyan-invert'].vertical-left .menu-search-bar li, .mega-menu[data-color='cyan-invert'].vertical-left .menu-search-bar form, .mega-menu[data-color='cyan-invert'].vertical-left .menu-search-bar label, .mega-menu[data-color='cyan-invert'].vertical-right .menu-search-bar input, .mega-menu[data-color='cyan-invert'].vertical-right .menu-search-bar li, .mega-menu[data-color='cyan-invert'].vertical-right .menu-search-bar form, .mega-menu[data-color='cyan-invert'].vertical-right .menu-search-bar label {
		background: #00a5bc
	}

}

.mega-menu[data-color='deep-orange'] {
}

.mega-menu[data-color='deep-orange'] > section.menu-list-items {
	background-color: #333
}

.mega-menu[data-color='deep-orange'] .menu-logo > li > a:hover {
	background-color: #fe5722
}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='deep-orange'] .menu-links > li.activeTriggerMobile {
		background-color: #fe5722
	}

}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='deep-orange'] .menu-links > li.activeTrigger {
		background-color: #fe5722
	}

}

.mega-menu[data-color='deep-orange'] .menu-links > li.active {
	background-color: #fe5722
}

.mega-menu[data-color='deep-orange'] .menu-links > li:hover {
	background-color: #fe5722
}

.mega-menu[data-color='deep-orange'] .menu-search-bar li:hover i.fas.fa-search {
	background: #fe5722
}

.mega-menu[data-color='deep-orange'] .menu-search-bar input:focus {
	background: #fe5722
}

.mega-menu[data-color='deep-orange'] .menu-mobile-collapse-trigger {
	background: #1a1a1a
}

.mega-menu[data-color='deep-orange'] .menu-mobile-collapse-trigger:hover {
	background: black
}

.mega-menu[data-color='deep-orange'] .drop-down-multilevel li:hover {
	background: #fe5722
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='deep-orange'] .drop-down-multilevel li.activeTrigger {
		background: #fe5722
	}

}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='deep-orange'] .drop-down-multilevel li.activeTriggerMobile {
		background: #fe5722
	}

}

.mega-menu[data-color='deep-orange'] .drop-down input[type="submit"], .mega-menu[data-color='deep-orange'] .drop-down input[type="button"], .mega-menu[data-color='deep-orange'] .drop-down button[type=submit], .mega-menu[data-color='deep-orange'] .drop-down button[type=reset], .mega-menu[data-color='deep-orange'] .drop-down-tab-bar input[type="submit"], .mega-menu[data-color='deep-orange'] .drop-down-tab-bar input[type="button"], .mega-menu[data-color='deep-orange'] .drop-down-tab-bar button[type=submit], .mega-menu[data-color='deep-orange'] .drop-down-tab-bar button[type=reset] {
	background: #fe5722
}

.mega-menu[data-color='deep-orange'] .drop-down input[type="submit"]:hover, .mega-menu[data-color='deep-orange'] .drop-down input[type="button"]:hover, .mega-menu[data-color='deep-orange'] .drop-down button[type=submit]:hover, .mega-menu[data-color='deep-orange'] .drop-down button[type=reset]:hover, .mega-menu[data-color='deep-orange'] .drop-down-tab-bar input[type="submit"]:hover, .mega-menu[data-color='deep-orange'] .drop-down-tab-bar input[type="button"]:hover, .mega-menu[data-color='deep-orange'] .drop-down-tab-bar button[type=submit]:hover, .mega-menu[data-color='deep-orange'] .drop-down-tab-bar button[type=reset]:hover {
	background-color: #333
}

.mega-menu[data-color='deep-orange'] .drop-down a:hover, .mega-menu[data-color='deep-orange'] .drop-down-tab-bar a:hover {
	color: #fe5722
}

.mega-menu[data-color='deep-orange'] .drop-down::-moz-selection, .mega-menu[data-color='deep-orange'] .drop-down-tab-bar::-moz-selection {
	background: #fea488
}

.mega-menu[data-color='deep-orange'] .drop-down::selection, .mega-menu[data-color='deep-orange'] .drop-down-tab-bar::selection {
	background: #fea488
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='deep-orange'].vertical-left .menu-search-bar input, .mega-menu[data-color='deep-orange'].vertical-left .menu-search-bar li, .mega-menu[data-color='deep-orange'].vertical-left .menu-search-bar form, .mega-menu[data-color='deep-orange'].vertical-left .menu-search-bar label, .mega-menu[data-color='deep-orange'].vertical-right .menu-search-bar input, .mega-menu[data-color='deep-orange'].vertical-right .menu-search-bar li, .mega-menu[data-color='deep-orange'].vertical-right .menu-search-bar form, .mega-menu[data-color='deep-orange'].vertical-right .menu-search-bar label {
		background: #fe5722
	}

}

.mega-menu[data-color='deep-orange-invert'] {
}

.mega-menu[data-color='deep-orange-invert'] > section.menu-list-items {
	background-color: #fe6a3b
}

.mega-menu[data-color='deep-orange-invert'] .menu-logo > li > a:hover {
	background-color: #fe4409
}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='deep-orange-invert'] .menu-links > li.activeTriggerMobile {
		background-color: #fe4409
	}

}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='deep-orange-invert'] .menu-links > li.activeTrigger {
		background-color: #fe4409
	}

}

.mega-menu[data-color='deep-orange-invert'] .menu-links > li.active {
	background-color: #fe4409
}

.mega-menu[data-color='deep-orange-invert'] .menu-links > li:hover {
	background-color: #fe4409
}

.mega-menu[data-color='deep-orange-invert'] .menu-search-bar li:hover i.fas.fa-search {
	background: #fe4409
}

.mega-menu[data-color='deep-orange-invert'] .menu-search-bar input:focus {
	background: #fe4409
}

.mega-menu[data-color='deep-orange-invert'] .menu-mobile-collapse-trigger {
	background: #fe4409
}

.mega-menu[data-color='deep-orange-invert'] .menu-mobile-collapse-trigger:hover {
	background: #d33301
}

.mega-menu[data-color='deep-orange-invert'] .drop-down-multilevel li:hover {
	background: #fe4409
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='deep-orange-invert'] .drop-down-multilevel li.activeTrigger {
		background: #fe4409
	}

}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='deep-orange-invert'] .drop-down-multilevel li.activeTriggerMobile {
		background: #fe4409
	}

}

.mega-menu[data-color='deep-orange-invert'] .drop-down input[type="submit"], .mega-menu[data-color='deep-orange-invert'] .drop-down input[type="button"], .mega-menu[data-color='deep-orange-invert'] .drop-down button[type=submit], .mega-menu[data-color='deep-orange-invert'] .drop-down button[type=reset], .mega-menu[data-color='deep-orange-invert'] .drop-down-tab-bar input[type="submit"], .mega-menu[data-color='deep-orange-invert'] .drop-down-tab-bar input[type="button"], .mega-menu[data-color='deep-orange-invert'] .drop-down-tab-bar button[type=submit], .mega-menu[data-color='deep-orange-invert'] .drop-down-tab-bar button[type=reset] {
	background: #fe4409
}

.mega-menu[data-color='deep-orange-invert'] .drop-down input[type="submit"]:hover, .mega-menu[data-color='deep-orange-invert'] .drop-down input[type="button"]:hover, .mega-menu[data-color='deep-orange-invert'] .drop-down button[type=submit]:hover, .mega-menu[data-color='deep-orange-invert'] .drop-down button[type=reset]:hover, .mega-menu[data-color='deep-orange-invert'] .drop-down-tab-bar input[type="submit"]:hover, .mega-menu[data-color='deep-orange-invert'] .drop-down-tab-bar input[type="button"]:hover, .mega-menu[data-color='deep-orange-invert'] .drop-down-tab-bar button[type=submit]:hover, .mega-menu[data-color='deep-orange-invert'] .drop-down-tab-bar button[type=reset]:hover {
	background-color: #fe6a3b
}

.mega-menu[data-color='deep-orange-invert'] .drop-down a:hover, .mega-menu[data-color='deep-orange-invert'] .drop-down-tab-bar a:hover {
	color: #fe4409
}

.mega-menu[data-color='deep-orange-invert'] .drop-down::-moz-selection, .mega-menu[data-color='deep-orange-invert'] .drop-down-tab-bar::-moz-selection {
	background: #fe916e
}

.mega-menu[data-color='deep-orange-invert'] .drop-down::selection, .mega-menu[data-color='deep-orange-invert'] .drop-down-tab-bar::selection {
	background: #fe916e
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='deep-orange-invert'].vertical-left .menu-search-bar input, .mega-menu[data-color='deep-orange-invert'].vertical-left .menu-search-bar li, .mega-menu[data-color='deep-orange-invert'].vertical-left .menu-search-bar form, .mega-menu[data-color='deep-orange-invert'].vertical-left .menu-search-bar label, .mega-menu[data-color='deep-orange-invert'].vertical-right .menu-search-bar input, .mega-menu[data-color='deep-orange-invert'].vertical-right .menu-search-bar li, .mega-menu[data-color='deep-orange-invert'].vertical-right .menu-search-bar form, .mega-menu[data-color='deep-orange-invert'].vertical-right .menu-search-bar label {
		background: #fe4409
	}

}

.mega-menu[data-color='deep-purple'] {
}

.mega-menu[data-color='deep-purple'] > section.menu-list-items {
	background-color: #333
}

.mega-menu[data-color='deep-purple'] .menu-logo > li > a:hover {
	background-color: #673bb7
}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='deep-purple'] .menu-links > li.activeTriggerMobile {
		background-color: #673bb7
	}

}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='deep-purple'] .menu-links > li.activeTrigger {
		background-color: #673bb7
	}

}

.mega-menu[data-color='deep-purple'] .menu-links > li.active {
	background-color: #673bb7
}

.mega-menu[data-color='deep-purple'] .menu-links > li:hover {
	background-color: #673bb7
}

.mega-menu[data-color='deep-purple'] .menu-search-bar li:hover i.fas.fa-search {
	background: #673bb7
}

.mega-menu[data-color='deep-purple'] .menu-search-bar input:focus {
	background: #673bb7
}

.mega-menu[data-color='deep-purple'] .menu-mobile-collapse-trigger {
	background: #1a1a1a
}

.mega-menu[data-color='deep-purple'] .menu-mobile-collapse-trigger:hover {
	background: black
}

.mega-menu[data-color='deep-purple'] .drop-down-multilevel li:hover {
	background: #673bb7
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='deep-purple'] .drop-down-multilevel li.activeTrigger {
		background: #673bb7
	}

}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='deep-purple'] .drop-down-multilevel li.activeTriggerMobile {
		background: #673bb7
	}

}

.mega-menu[data-color='deep-purple'] .drop-down input[type="submit"], .mega-menu[data-color='deep-purple'] .drop-down input[type="button"], .mega-menu[data-color='deep-purple'] .drop-down button[type=submit], .mega-menu[data-color='deep-purple'] .drop-down button[type=reset], .mega-menu[data-color='deep-purple'] .drop-down-tab-bar input[type="submit"], .mega-menu[data-color='deep-purple'] .drop-down-tab-bar input[type="button"], .mega-menu[data-color='deep-purple'] .drop-down-tab-bar button[type=submit], .mega-menu[data-color='deep-purple'] .drop-down-tab-bar button[type=reset] {
	background: #673bb7
}

.mega-menu[data-color='deep-purple'] .drop-down input[type="submit"]:hover, .mega-menu[data-color='deep-purple'] .drop-down input[type="button"]:hover, .mega-menu[data-color='deep-purple'] .drop-down button[type=submit]:hover, .mega-menu[data-color='deep-purple'] .drop-down button[type=reset]:hover, .mega-menu[data-color='deep-purple'] .drop-down-tab-bar input[type="submit"]:hover, .mega-menu[data-color='deep-purple'] .drop-down-tab-bar input[type="button"]:hover, .mega-menu[data-color='deep-purple'] .drop-down-tab-bar button[type=submit]:hover, .mega-menu[data-color='deep-purple'] .drop-down-tab-bar button[type=reset]:hover {
	background-color: #333
}

.mega-menu[data-color='deep-purple'] .drop-down a:hover, .mega-menu[data-color='deep-purple'] .drop-down-tab-bar a:hover {
	color: #673bb7
}

.mega-menu[data-color='deep-purple'] .drop-down::-moz-selection, .mega-menu[data-color='deep-purple'] .drop-down-tab-bar::-moz-selection {
	background: #a081d7
}

.mega-menu[data-color='deep-purple'] .drop-down::selection, .mega-menu[data-color='deep-purple'] .drop-down-tab-bar::selection {
	background: #a081d7
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='deep-purple'].vertical-left .menu-search-bar input, .mega-menu[data-color='deep-purple'].vertical-left .menu-search-bar li, .mega-menu[data-color='deep-purple'].vertical-left .menu-search-bar form, .mega-menu[data-color='deep-purple'].vertical-left .menu-search-bar label, .mega-menu[data-color='deep-purple'].vertical-right .menu-search-bar input, .mega-menu[data-color='deep-purple'].vertical-right .menu-search-bar li, .mega-menu[data-color='deep-purple'].vertical-right .menu-search-bar form, .mega-menu[data-color='deep-purple'].vertical-right .menu-search-bar label {
		background: #673bb7
	}

}

.mega-menu[data-color='deep-purple-invert'] {
}

.mega-menu[data-color='deep-purple-invert'] > section.menu-list-items {
	background-color: #7448c4
}

.mega-menu[data-color='deep-purple-invert'] .menu-logo > li > a:hover {
	background-color: #5c35a4
}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='deep-purple-invert'] .menu-links > li.activeTriggerMobile {
		background-color: #5c35a4
	}

}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='deep-purple-invert'] .menu-links > li.activeTrigger {
		background-color: #5c35a4
	}

}

.mega-menu[data-color='deep-purple-invert'] .menu-links > li.active {
	background-color: #5c35a4
}

.mega-menu[data-color='deep-purple-invert'] .menu-links > li:hover {
	background-color: #5c35a4
}

.mega-menu[data-color='deep-purple-invert'] .menu-search-bar li:hover i.fas.fa-search {
	background: #5c35a4
}

.mega-menu[data-color='deep-purple-invert'] .menu-search-bar input:focus {
	background: #5c35a4
}

.mega-menu[data-color='deep-purple-invert'] .menu-mobile-collapse-trigger {
	background: #5c35a4
}

.mega-menu[data-color='deep-purple-invert'] .menu-mobile-collapse-trigger:hover {
	background: #46287d
}

.mega-menu[data-color='deep-purple-invert'] .drop-down-multilevel li:hover {
	background: #5c35a4
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='deep-purple-invert'] .drop-down-multilevel li.activeTrigger {
		background: #5c35a4
	}

}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='deep-purple-invert'] .drop-down-multilevel li.activeTriggerMobile {
		background: #5c35a4
	}

}

.mega-menu[data-color='deep-purple-invert'] .drop-down input[type="submit"], .mega-menu[data-color='deep-purple-invert'] .drop-down input[type="button"], .mega-menu[data-color='deep-purple-invert'] .drop-down button[type=submit], .mega-menu[data-color='deep-purple-invert'] .drop-down button[type=reset], .mega-menu[data-color='deep-purple-invert'] .drop-down-tab-bar input[type="submit"], .mega-menu[data-color='deep-purple-invert'] .drop-down-tab-bar input[type="button"], .mega-menu[data-color='deep-purple-invert'] .drop-down-tab-bar button[type=submit], .mega-menu[data-color='deep-purple-invert'] .drop-down-tab-bar button[type=reset] {
	background: #5c35a4
}

.mega-menu[data-color='deep-purple-invert'] .drop-down input[type="submit"]:hover, .mega-menu[data-color='deep-purple-invert'] .drop-down input[type="button"]:hover, .mega-menu[data-color='deep-purple-invert'] .drop-down button[type=submit]:hover, .mega-menu[data-color='deep-purple-invert'] .drop-down button[type=reset]:hover, .mega-menu[data-color='deep-purple-invert'] .drop-down-tab-bar input[type="submit"]:hover, .mega-menu[data-color='deep-purple-invert'] .drop-down-tab-bar input[type="button"]:hover, .mega-menu[data-color='deep-purple-invert'] .drop-down-tab-bar button[type=submit]:hover, .mega-menu[data-color='deep-purple-invert'] .drop-down-tab-bar button[type=reset]:hover {
	background-color: #7448c4
}

.mega-menu[data-color='deep-purple-invert'] .drop-down a:hover, .mega-menu[data-color='deep-purple-invert'] .drop-down-tab-bar a:hover {
	color: #5c35a4
}

.mega-menu[data-color='deep-purple-invert'] .drop-down::-moz-selection, .mega-menu[data-color='deep-purple-invert'] .drop-down-tab-bar::-moz-selection {
	background: #916ed0
}

.mega-menu[data-color='deep-purple-invert'] .drop-down::selection, .mega-menu[data-color='deep-purple-invert'] .drop-down-tab-bar::selection {
	background: #916ed0
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='deep-purple-invert'].vertical-left .menu-search-bar input, .mega-menu[data-color='deep-purple-invert'].vertical-left .menu-search-bar li, .mega-menu[data-color='deep-purple-invert'].vertical-left .menu-search-bar form, .mega-menu[data-color='deep-purple-invert'].vertical-left .menu-search-bar label, .mega-menu[data-color='deep-purple-invert'].vertical-right .menu-search-bar input, .mega-menu[data-color='deep-purple-invert'].vertical-right .menu-search-bar li, .mega-menu[data-color='deep-purple-invert'].vertical-right .menu-search-bar form, .mega-menu[data-color='deep-purple-invert'].vertical-right .menu-search-bar label {
		background: #5c35a4
	}

}

.mega-menu[data-color='grey'] {
}

.mega-menu[data-color='grey'] > section.menu-list-items {
	background-color: #333
}

.mega-menu[data-color='grey'] .menu-logo > li > a:hover {
	background-color: #787878
}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='grey'] .menu-links > li.activeTriggerMobile {
		background-color: #787878
	}

}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='grey'] .menu-links > li.activeTrigger {
		background-color: #787878
	}

}

.mega-menu[data-color='grey'] .menu-links > li.active {
	background-color: #787878
}

.mega-menu[data-color='grey'] .menu-links > li:hover {
	background-color: #787878
}

.mega-menu[data-color='grey'] .menu-search-bar li:hover i.fas.fa-search {
	background: #787878
}

.mega-menu[data-color='grey'] .menu-search-bar input:focus {
	background: #787878
}

.mega-menu[data-color='grey'] .menu-mobile-collapse-trigger {
	background: #1a1a1a
}

.mega-menu[data-color='grey'] .menu-mobile-collapse-trigger:hover {
	background: black
}

.mega-menu[data-color='grey'] .drop-down-multilevel li:hover {
	background: #787878
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='grey'] .drop-down-multilevel li.activeTrigger {
		background: #787878
	}

}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='grey'] .drop-down-multilevel li.activeTriggerMobile {
		background: #787878
	}

}

.mega-menu[data-color='grey'] .drop-down input[type="submit"], .mega-menu[data-color='grey'] .drop-down input[type="button"], .mega-menu[data-color='grey'] .drop-down button[type=submit], .mega-menu[data-color='grey'] .drop-down button[type=reset], .mega-menu[data-color='grey'] .drop-down-tab-bar input[type="submit"], .mega-menu[data-color='grey'] .drop-down-tab-bar input[type="button"], .mega-menu[data-color='grey'] .drop-down-tab-bar button[type=submit], .mega-menu[data-color='grey'] .drop-down-tab-bar button[type=reset] {
	background: #787878
}

.mega-menu[data-color='grey'] .drop-down input[type="submit"]:hover, .mega-menu[data-color='grey'] .drop-down input[type="button"]:hover, .mega-menu[data-color='grey'] .drop-down button[type=submit]:hover, .mega-menu[data-color='grey'] .drop-down button[type=reset]:hover, .mega-menu[data-color='grey'] .drop-down-tab-bar input[type="submit"]:hover, .mega-menu[data-color='grey'] .drop-down-tab-bar input[type="button"]:hover, .mega-menu[data-color='grey'] .drop-down-tab-bar button[type=submit]:hover, .mega-menu[data-color='grey'] .drop-down-tab-bar button[type=reset]:hover {
	background-color: #333
}

.mega-menu[data-color='grey'] .drop-down a:hover, .mega-menu[data-color='grey'] .drop-down-tab-bar a:hover {
	color: #787878
}

.mega-menu[data-color='grey'] .drop-down::-moz-selection, .mega-menu[data-color='grey'] .drop-down-tab-bar::-moz-selection {
	background: #ababab
}

.mega-menu[data-color='grey'] .drop-down::selection, .mega-menu[data-color='grey'] .drop-down-tab-bar::selection {
	background: #ababab
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='grey'].vertical-left .menu-search-bar input, .mega-menu[data-color='grey'].vertical-left .menu-search-bar li, .mega-menu[data-color='grey'].vertical-left .menu-search-bar form, .mega-menu[data-color='grey'].vertical-left .menu-search-bar label, .mega-menu[data-color='grey'].vertical-right .menu-search-bar input, .mega-menu[data-color='grey'].vertical-right .menu-search-bar li, .mega-menu[data-color='grey'].vertical-right .menu-search-bar form, .mega-menu[data-color='grey'].vertical-right .menu-search-bar label {
		background: #787878
	}

}

.mega-menu[data-color='grey-invert'] {
}

.mega-menu[data-color='grey-invert'] > section.menu-list-items {
	background-color: #787878
}

.mega-menu[data-color='grey-invert'] .menu-logo > li > a:hover {
	background-color: #6b6b6b
}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='grey-invert'] .menu-links > li.activeTriggerMobile {
		background-color: #6b6b6b
	}

}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='grey-invert'] .menu-links > li.activeTrigger {
		background-color: #6b6b6b
	}

}

.mega-menu[data-color='grey-invert'] .menu-links > li.active {
	background-color: #6b6b6b
}

.mega-menu[data-color='grey-invert'] .menu-links > li:hover {
	background-color: #6b6b6b
}

.mega-menu[data-color='grey-invert'] .menu-search-bar li:hover i.fas.fa-search {
	background: #6b6b6b
}

.mega-menu[data-color='grey-invert'] .menu-search-bar input:focus {
	background: #6b6b6b
}

.mega-menu[data-color='grey-invert'] .menu-mobile-collapse-trigger {
	background: #5e5e5e
}

.mega-menu[data-color='grey-invert'] .menu-mobile-collapse-trigger:hover {
	background: #454545
}

.mega-menu[data-color='grey-invert'] .drop-down-multilevel li:hover {
	background: #6b6b6b
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='grey-invert'] .drop-down-multilevel li.activeTrigger {
		background: #6b6b6b
	}

}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='grey-invert'] .drop-down-multilevel li.activeTriggerMobile {
		background: #6b6b6b
	}

}

.mega-menu[data-color='grey-invert'] .drop-down input[type="submit"], .mega-menu[data-color='grey-invert'] .drop-down input[type="button"], .mega-menu[data-color='grey-invert'] .drop-down button[type=submit], .mega-menu[data-color='grey-invert'] .drop-down button[type=reset], .mega-menu[data-color='grey-invert'] .drop-down-tab-bar input[type="submit"], .mega-menu[data-color='grey-invert'] .drop-down-tab-bar input[type="button"], .mega-menu[data-color='grey-invert'] .drop-down-tab-bar button[type=submit], .mega-menu[data-color='grey-invert'] .drop-down-tab-bar button[type=reset] {
	background: #6b6b6b
}

.mega-menu[data-color='grey-invert'] .drop-down input[type="submit"]:hover, .mega-menu[data-color='grey-invert'] .drop-down input[type="button"]:hover, .mega-menu[data-color='grey-invert'] .drop-down button[type=submit]:hover, .mega-menu[data-color='grey-invert'] .drop-down button[type=reset]:hover, .mega-menu[data-color='grey-invert'] .drop-down-tab-bar input[type="submit"]:hover, .mega-menu[data-color='grey-invert'] .drop-down-tab-bar input[type="button"]:hover, .mega-menu[data-color='grey-invert'] .drop-down-tab-bar button[type=submit]:hover, .mega-menu[data-color='grey-invert'] .drop-down-tab-bar button[type=reset]:hover {
	background-color: #787878
}

.mega-menu[data-color='grey-invert'] .drop-down a:hover, .mega-menu[data-color='grey-invert'] .drop-down-tab-bar a:hover {
	color: #6b6b6b
}

.mega-menu[data-color='grey-invert'] .drop-down::-moz-selection, .mega-menu[data-color='grey-invert'] .drop-down-tab-bar::-moz-selection {
	background: #9e9e9e
}

.mega-menu[data-color='grey-invert'] .drop-down::selection, .mega-menu[data-color='grey-invert'] .drop-down-tab-bar::selection {
	background: #9e9e9e
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='grey-invert'].vertical-left .menu-search-bar input, .mega-menu[data-color='grey-invert'].vertical-left .menu-search-bar li, .mega-menu[data-color='grey-invert'].vertical-left .menu-search-bar form, .mega-menu[data-color='grey-invert'].vertical-left .menu-search-bar label, .mega-menu[data-color='grey-invert'].vertical-right .menu-search-bar input, .mega-menu[data-color='grey-invert'].vertical-right .menu-search-bar li, .mega-menu[data-color='grey-invert'].vertical-right .menu-search-bar form, .mega-menu[data-color='grey-invert'].vertical-right .menu-search-bar label {
		background: #6b6b6b
	}

}

.mega-menu[data-color='indigo'] {
}

.mega-menu[data-color='indigo'] > section.menu-list-items {
	background-color: #333
}

.mega-menu[data-color='indigo'] .menu-logo > li > a:hover {
	background-color: #3f51b5
}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='indigo'] .menu-links > li.activeTriggerMobile {
		background-color: #3f51b5
	}

}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='indigo'] .menu-links > li.activeTrigger {
		background-color: #3f51b5
	}

}

.mega-menu[data-color='indigo'] .menu-links > li.active {
	background-color: #3f51b5
}

.mega-menu[data-color='indigo'] .menu-links > li:hover {
	background-color: #3f51b5
}

.mega-menu[data-color='indigo'] .menu-search-bar li:hover i.fas.fa-search {
	background: #3f51b5
}

.mega-menu[data-color='indigo'] .menu-search-bar input:focus {
	background: #3f51b5
}

.mega-menu[data-color='indigo'] .menu-mobile-collapse-trigger {
	background: #1a1a1a
}

.mega-menu[data-color='indigo'] .menu-mobile-collapse-trigger:hover {
	background: black
}

.mega-menu[data-color='indigo'] .drop-down-multilevel li:hover {
	background: #3f51b5
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='indigo'] .drop-down-multilevel li.activeTrigger {
		background: #3f51b5
	}

}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='indigo'] .drop-down-multilevel li.activeTriggerMobile {
		background: #3f51b5
	}

}

.mega-menu[data-color='indigo'] .drop-down input[type="submit"], .mega-menu[data-color='indigo'] .drop-down input[type="button"], .mega-menu[data-color='indigo'] .drop-down button[type=submit], .mega-menu[data-color='indigo'] .drop-down button[type=reset], .mega-menu[data-color='indigo'] .drop-down-tab-bar input[type="submit"], .mega-menu[data-color='indigo'] .drop-down-tab-bar input[type="button"], .mega-menu[data-color='indigo'] .drop-down-tab-bar button[type=submit], .mega-menu[data-color='indigo'] .drop-down-tab-bar button[type=reset] {
	background: #3f51b5
}

.mega-menu[data-color='indigo'] .drop-down input[type="submit"]:hover, .mega-menu[data-color='indigo'] .drop-down input[type="button"]:hover, .mega-menu[data-color='indigo'] .drop-down button[type=submit]:hover, .mega-menu[data-color='indigo'] .drop-down button[type=reset]:hover, .mega-menu[data-color='indigo'] .drop-down-tab-bar input[type="submit"]:hover, .mega-menu[data-color='indigo'] .drop-down-tab-bar input[type="button"]:hover, .mega-menu[data-color='indigo'] .drop-down-tab-bar button[type=submit]:hover, .mega-menu[data-color='indigo'] .drop-down-tab-bar button[type=reset]:hover {
	background-color: #333
}

.mega-menu[data-color='indigo'] .drop-down a:hover, .mega-menu[data-color='indigo'] .drop-down-tab-bar a:hover {
	color: #3f51b5
}

.mega-menu[data-color='indigo'] .drop-down::-moz-selection, .mega-menu[data-color='indigo'] .drop-down-tab-bar::-moz-selection {
	background: #8591d5
}

.mega-menu[data-color='indigo'] .drop-down::selection, .mega-menu[data-color='indigo'] .drop-down-tab-bar::selection {
	background: #8591d5
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='indigo'].vertical-left .menu-search-bar input, .mega-menu[data-color='indigo'].vertical-left .menu-search-bar li, .mega-menu[data-color='indigo'].vertical-left .menu-search-bar form, .mega-menu[data-color='indigo'].vertical-left .menu-search-bar label, .mega-menu[data-color='indigo'].vertical-right .menu-search-bar input, .mega-menu[data-color='indigo'].vertical-right .menu-search-bar li, .mega-menu[data-color='indigo'].vertical-right .menu-search-bar form, .mega-menu[data-color='indigo'].vertical-right .menu-search-bar label {
		background: #3f51b5
	}

}

.mega-menu[data-color='indigo-invert'] {
}

.mega-menu[data-color='indigo-invert'] > section.menu-list-items {
	background-color: #4d5ec1
}

.mega-menu[data-color='indigo-invert'] .menu-logo > li > a:hover {
	background-color: #3849a2
}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='indigo-invert'] .menu-links > li.activeTriggerMobile {
		background-color: #3849a2
	}

}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='indigo-invert'] .menu-links > li.activeTrigger {
		background-color: #3849a2
	}

}

.mega-menu[data-color='indigo-invert'] .menu-links > li.active {
	background-color: #3849a2
}

.mega-menu[data-color='indigo-invert'] .menu-links > li:hover {
	background-color: #3849a2
}

.mega-menu[data-color='indigo-invert'] .menu-search-bar li:hover i.fas.fa-search {
	background: #3849a2
}

.mega-menu[data-color='indigo-invert'] .menu-search-bar input:focus {
	background: #3849a2
}

.mega-menu[data-color='indigo-invert'] .menu-mobile-collapse-trigger {
	background: #3849a2
}

.mega-menu[data-color='indigo-invert'] .menu-mobile-collapse-trigger:hover {
	background: #2b387c
}

.mega-menu[data-color='indigo-invert'] .drop-down-multilevel li:hover {
	background: #3849a2
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='indigo-invert'] .drop-down-multilevel li.activeTrigger {
		background: #3849a2
	}

}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='indigo-invert'] .drop-down-multilevel li.activeTriggerMobile {
		background: #3849a2
	}

}

.mega-menu[data-color='indigo-invert'] .drop-down input[type="submit"], .mega-menu[data-color='indigo-invert'] .drop-down input[type="button"], .mega-menu[data-color='indigo-invert'] .drop-down button[type=submit], .mega-menu[data-color='indigo-invert'] .drop-down button[type=reset], .mega-menu[data-color='indigo-invert'] .drop-down-tab-bar input[type="submit"], .mega-menu[data-color='indigo-invert'] .drop-down-tab-bar input[type="button"], .mega-menu[data-color='indigo-invert'] .drop-down-tab-bar button[type=submit], .mega-menu[data-color='indigo-invert'] .drop-down-tab-bar button[type=reset] {
	background: #3849a2
}

.mega-menu[data-color='indigo-invert'] .drop-down input[type="submit"]:hover, .mega-menu[data-color='indigo-invert'] .drop-down input[type="button"]:hover, .mega-menu[data-color='indigo-invert'] .drop-down button[type=submit]:hover, .mega-menu[data-color='indigo-invert'] .drop-down button[type=reset]:hover, .mega-menu[data-color='indigo-invert'] .drop-down-tab-bar input[type="submit"]:hover, .mega-menu[data-color='indigo-invert'] .drop-down-tab-bar input[type="button"]:hover, .mega-menu[data-color='indigo-invert'] .drop-down-tab-bar button[type=submit]:hover, .mega-menu[data-color='indigo-invert'] .drop-down-tab-bar button[type=reset]:hover {
	background-color: #4d5ec1
}

.mega-menu[data-color='indigo-invert'] .drop-down a:hover, .mega-menu[data-color='indigo-invert'] .drop-down-tab-bar a:hover {
	color: #3849a2
}

.mega-menu[data-color='indigo-invert'] .drop-down::-moz-selection, .mega-menu[data-color='indigo-invert'] .drop-down-tab-bar::-moz-selection {
	background: #7280ce
}

.mega-menu[data-color='indigo-invert'] .drop-down::selection, .mega-menu[data-color='indigo-invert'] .drop-down-tab-bar::selection {
	background: #7280ce
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='indigo-invert'].vertical-left .menu-search-bar input, .mega-menu[data-color='indigo-invert'].vertical-left .menu-search-bar li, .mega-menu[data-color='indigo-invert'].vertical-left .menu-search-bar form, .mega-menu[data-color='indigo-invert'].vertical-left .menu-search-bar label, .mega-menu[data-color='indigo-invert'].vertical-right .menu-search-bar input, .mega-menu[data-color='indigo-invert'].vertical-right .menu-search-bar li, .mega-menu[data-color='indigo-invert'].vertical-right .menu-search-bar form, .mega-menu[data-color='indigo-invert'].vertical-right .menu-search-bar label {
		background: #3849a2
	}

}

.mega-menu[data-color='light-blue'] {
}

.mega-menu[data-color='light-blue'] > section.menu-list-items {
	background-color: #333
}

.mega-menu[data-color='light-blue'] .menu-logo > li > a:hover {
	background-color: #0af
}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='light-blue'] .menu-links > li.activeTriggerMobile {
		background-color: #0af
	}

}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='light-blue'] .menu-links > li.activeTrigger {
		background-color: #0af
	}

}

.mega-menu[data-color='light-blue'] .menu-links > li.active {
	background-color: #0af
}

.mega-menu[data-color='light-blue'] .menu-links > li:hover {
	background-color: #0af
}

.mega-menu[data-color='light-blue'] .menu-search-bar li:hover i.fas.fa-search {
	background: #0af
}

.mega-menu[data-color='light-blue'] .menu-search-bar input:focus {
	background: #0af
}

.mega-menu[data-color='light-blue'] .menu-mobile-collapse-trigger {
	background: #1a1a1a
}

.mega-menu[data-color='light-blue'] .menu-mobile-collapse-trigger:hover {
	background: black
}

.mega-menu[data-color='light-blue'] .drop-down-multilevel li:hover {
	background: #0af
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='light-blue'] .drop-down-multilevel li.activeTrigger {
		background: #0af
	}

}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='light-blue'] .drop-down-multilevel li.activeTriggerMobile {
		background: #0af
	}

}

.mega-menu[data-color='light-blue'] .drop-down input[type="submit"], .mega-menu[data-color='light-blue'] .drop-down input[type="button"], .mega-menu[data-color='light-blue'] .drop-down button[type=submit], .mega-menu[data-color='light-blue'] .drop-down button[type=reset], .mega-menu[data-color='light-blue'] .drop-down-tab-bar input[type="submit"], .mega-menu[data-color='light-blue'] .drop-down-tab-bar input[type="button"], .mega-menu[data-color='light-blue'] .drop-down-tab-bar button[type=submit], .mega-menu[data-color='light-blue'] .drop-down-tab-bar button[type=reset] {
	background: #0af
}

.mega-menu[data-color='light-blue'] .drop-down input[type="submit"]:hover, .mega-menu[data-color='light-blue'] .drop-down input[type="button"]:hover, .mega-menu[data-color='light-blue'] .drop-down button[type=submit]:hover, .mega-menu[data-color='light-blue'] .drop-down button[type=reset]:hover, .mega-menu[data-color='light-blue'] .drop-down-tab-bar input[type="submit"]:hover, .mega-menu[data-color='light-blue'] .drop-down-tab-bar input[type="button"]:hover, .mega-menu[data-color='light-blue'] .drop-down-tab-bar button[type=submit]:hover, .mega-menu[data-color='light-blue'] .drop-down-tab-bar button[type=reset]:hover {
	background-color: #333
}

.mega-menu[data-color='light-blue'] .drop-down a:hover, .mega-menu[data-color='light-blue'] .drop-down-tab-bar a:hover {
	color: #0af
}

.mega-menu[data-color='light-blue'] .drop-down::-moz-selection, .mega-menu[data-color='light-blue'] .drop-down-tab-bar::-moz-selection {
	background: #66ccff
}

.mega-menu[data-color='light-blue'] .drop-down::selection, .mega-menu[data-color='light-blue'] .drop-down-tab-bar::selection {
	background: #66ccff
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='light-blue'].vertical-left .menu-search-bar input, .mega-menu[data-color='light-blue'].vertical-left .menu-search-bar li, .mega-menu[data-color='light-blue'].vertical-left .menu-search-bar form, .mega-menu[data-color='light-blue'].vertical-left .menu-search-bar label, .mega-menu[data-color='light-blue'].vertical-right .menu-search-bar input, .mega-menu[data-color='light-blue'].vertical-right .menu-search-bar li, .mega-menu[data-color='light-blue'].vertical-right .menu-search-bar form, .mega-menu[data-color='light-blue'].vertical-right .menu-search-bar label {
		background: #0af
	}

}

.mega-menu[data-color='light-blue-invert'] {
}

.mega-menu[data-color='light-blue-invert'] > section.menu-list-items {
	background-color: #00aaff
}

.mega-menu[data-color='light-blue-invert'] .menu-logo > li > a:hover {
	background-color: #008fd6
}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='light-blue-invert'] .menu-links > li.activeTriggerMobile {
		background-color: #008fd6
	}

}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='light-blue-invert'] .menu-links > li.activeTrigger {
		background-color: #008fd6
	}

}

.mega-menu[data-color='light-blue-invert'] .menu-links > li.active {
	background-color: #008fd6
}

.mega-menu[data-color='light-blue-invert'] .menu-links > li:hover {
	background-color: #008fd6
}

.mega-menu[data-color='light-blue-invert'] .menu-search-bar li:hover i.fas.fa-search {
	background: #008fd6
}

.mega-menu[data-color='light-blue-invert'] .menu-search-bar input:focus {
	background: #008fd6
}

.mega-menu[data-color='light-blue-invert'] .menu-mobile-collapse-trigger {
	background: #0088cc
}

.mega-menu[data-color='light-blue-invert'] .menu-mobile-collapse-trigger:hover {
	background: #006699
}

.mega-menu[data-color='light-blue-invert'] .drop-down-multilevel li:hover {
	background: #008fd6
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='light-blue-invert'] .drop-down-multilevel li.activeTrigger {
		background: #008fd6
	}

}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='light-blue-invert'] .drop-down-multilevel li.activeTriggerMobile {
		background: #008fd6
	}

}

.mega-menu[data-color='light-blue-invert'] .drop-down input[type="submit"], .mega-menu[data-color='light-blue-invert'] .drop-down input[type="button"], .mega-menu[data-color='light-blue-invert'] .drop-down button[type=submit], .mega-menu[data-color='light-blue-invert'] .drop-down button[type=reset], .mega-menu[data-color='light-blue-invert'] .drop-down-tab-bar input[type="submit"], .mega-menu[data-color='light-blue-invert'] .drop-down-tab-bar input[type="button"], .mega-menu[data-color='light-blue-invert'] .drop-down-tab-bar button[type=submit], .mega-menu[data-color='light-blue-invert'] .drop-down-tab-bar button[type=reset] {
	background: #008fd6
}

.mega-menu[data-color='light-blue-invert'] .drop-down input[type="submit"]:hover, .mega-menu[data-color='light-blue-invert'] .drop-down input[type="button"]:hover, .mega-menu[data-color='light-blue-invert'] .drop-down button[type=submit]:hover, .mega-menu[data-color='light-blue-invert'] .drop-down button[type=reset]:hover, .mega-menu[data-color='light-blue-invert'] .drop-down-tab-bar input[type="submit"]:hover, .mega-menu[data-color='light-blue-invert'] .drop-down-tab-bar input[type="button"]:hover, .mega-menu[data-color='light-blue-invert'] .drop-down-tab-bar button[type=submit]:hover, .mega-menu[data-color='light-blue-invert'] .drop-down-tab-bar button[type=reset]:hover {
	background-color: #00aaff
}

.mega-menu[data-color='light-blue-invert'] .drop-down a:hover, .mega-menu[data-color='light-blue-invert'] .drop-down-tab-bar a:hover {
	color: #008fd6
}

.mega-menu[data-color='light-blue-invert'] .drop-down::-moz-selection, .mega-menu[data-color='light-blue-invert'] .drop-down-tab-bar::-moz-selection {
	background: #3dbeff
}

.mega-menu[data-color='light-blue-invert'] .drop-down::selection, .mega-menu[data-color='light-blue-invert'] .drop-down-tab-bar::selection {
	background: #3dbeff
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='light-blue-invert'].vertical-left .menu-search-bar input, .mega-menu[data-color='light-blue-invert'].vertical-left .menu-search-bar li, .mega-menu[data-color='light-blue-invert'].vertical-left .menu-search-bar form, .mega-menu[data-color='light-blue-invert'].vertical-left .menu-search-bar label, .mega-menu[data-color='light-blue-invert'].vertical-right .menu-search-bar input, .mega-menu[data-color='light-blue-invert'].vertical-right .menu-search-bar li, .mega-menu[data-color='light-blue-invert'].vertical-right .menu-search-bar form, .mega-menu[data-color='light-blue-invert'].vertical-right .menu-search-bar label {
		background: #008fd6
	}

}

.mega-menu[data-color='light-green'] {
}

.mega-menu[data-color='light-green'] > section.menu-list-items {
	background-color: #333
}

.mega-menu[data-color='light-green'] .menu-logo > li > a:hover {
	background-color: #8bc24a
}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='light-green'] .menu-links > li.activeTriggerMobile {
		background-color: #8bc24a
	}

}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='light-green'] .menu-links > li.activeTrigger {
		background-color: #8bc24a
	}

}

.mega-menu[data-color='light-green'] .menu-links > li.active {
	background-color: #8bc24a
}

.mega-menu[data-color='light-green'] .menu-links > li:hover {
	background-color: #8bc24a
}

.mega-menu[data-color='light-green'] .menu-search-bar li:hover i.fas.fa-search {
	background: #8bc24a
}

.mega-menu[data-color='light-green'] .menu-search-bar input:focus {
	background: #8bc24a
}

.mega-menu[data-color='light-green'] .menu-mobile-collapse-trigger {
	background: #1a1a1a
}

.mega-menu[data-color='light-green'] .menu-mobile-collapse-trigger:hover {
	background: black
}

.mega-menu[data-color='light-green'] .drop-down-multilevel li:hover {
	background: #8bc24a
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='light-green'] .drop-down-multilevel li.activeTrigger {
		background: #8bc24a
	}

}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='light-green'] .drop-down-multilevel li.activeTriggerMobile {
		background: #8bc24a
	}

}

.mega-menu[data-color='light-green'] .drop-down input[type="submit"], .mega-menu[data-color='light-green'] .drop-down input[type="button"], .mega-menu[data-color='light-green'] .drop-down button[type=submit], .mega-menu[data-color='light-green'] .drop-down button[type=reset], .mega-menu[data-color='light-green'] .drop-down-tab-bar input[type="submit"], .mega-menu[data-color='light-green'] .drop-down-tab-bar input[type="button"], .mega-menu[data-color='light-green'] .drop-down-tab-bar button[type=submit], .mega-menu[data-color='light-green'] .drop-down-tab-bar button[type=reset] {
	background: #8bc24a
}

.mega-menu[data-color='light-green'] .drop-down input[type="submit"]:hover, .mega-menu[data-color='light-green'] .drop-down input[type="button"]:hover, .mega-menu[data-color='light-green'] .drop-down button[type=submit]:hover, .mega-menu[data-color='light-green'] .drop-down button[type=reset]:hover, .mega-menu[data-color='light-green'] .drop-down-tab-bar input[type="submit"]:hover, .mega-menu[data-color='light-green'] .drop-down-tab-bar input[type="button"]:hover, .mega-menu[data-color='light-green'] .drop-down-tab-bar button[type=submit]:hover, .mega-menu[data-color='light-green'] .drop-down-tab-bar button[type=reset]:hover {
	background-color: #333
}

.mega-menu[data-color='light-green'] .drop-down a:hover, .mega-menu[data-color='light-green'] .drop-down-tab-bar a:hover {
	color: #8bc24a
}

.mega-menu[data-color='light-green'] .drop-down::-moz-selection, .mega-menu[data-color='light-green'] .drop-down-tab-bar::-moz-selection {
	background: #bcdc96
}

.mega-menu[data-color='light-green'] .drop-down::selection, .mega-menu[data-color='light-green'] .drop-down-tab-bar::selection {
	background: #bcdc96
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='light-green'].vertical-left .menu-search-bar input, .mega-menu[data-color='light-green'].vertical-left .menu-search-bar li, .mega-menu[data-color='light-green'].vertical-left .menu-search-bar form, .mega-menu[data-color='light-green'].vertical-left .menu-search-bar label, .mega-menu[data-color='light-green'].vertical-right .menu-search-bar input, .mega-menu[data-color='light-green'].vertical-right .menu-search-bar li, .mega-menu[data-color='light-green'].vertical-right .menu-search-bar form, .mega-menu[data-color='light-green'].vertical-right .menu-search-bar label {
		background: #8bc24a
	}

}

.mega-menu[data-color='light-green-invert'] {
}

.mega-menu[data-color='light-green-invert'] > section.menu-list-items {
	background-color: #8bc24a
}

.mega-menu[data-color='light-green-invert'] .menu-logo > li > a:hover {
	background-color: #7eb53d
}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='light-green-invert'] .menu-links > li.activeTriggerMobile {
		background-color: #7eb53d
	}

}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='light-green-invert'] .menu-links > li.activeTrigger {
		background-color: #7eb53d
	}

}

.mega-menu[data-color='light-green-invert'] .menu-links > li.active {
	background-color: #7eb53d
}

.mega-menu[data-color='light-green-invert'] .menu-links > li:hover {
	background-color: #7eb53d
}

.mega-menu[data-color='light-green-invert'] .menu-search-bar li:hover i.fas.fa-search {
	background: #7eb53d
}

.mega-menu[data-color='light-green-invert'] .menu-search-bar input:focus {
	background: #7eb53d
}

.mega-menu[data-color='light-green-invert'] .menu-mobile-collapse-trigger {
	background: #71a237
}

.mega-menu[data-color='light-green-invert'] .menu-mobile-collapse-trigger:hover {
	background: #567c2a
}

.mega-menu[data-color='light-green-invert'] .drop-down-multilevel li:hover {
	background: #7eb53d
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='light-green-invert'] .drop-down-multilevel li.activeTrigger {
		background: #7eb53d
	}

}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='light-green-invert'] .drop-down-multilevel li.activeTriggerMobile {
		background: #7eb53d
	}

}

.mega-menu[data-color='light-green-invert'] .drop-down input[type="submit"], .mega-menu[data-color='light-green-invert'] .drop-down input[type="button"], .mega-menu[data-color='light-green-invert'] .drop-down button[type=submit], .mega-menu[data-color='light-green-invert'] .drop-down button[type=reset], .mega-menu[data-color='light-green-invert'] .drop-down-tab-bar input[type="submit"], .mega-menu[data-color='light-green-invert'] .drop-down-tab-bar input[type="button"], .mega-menu[data-color='light-green-invert'] .drop-down-tab-bar button[type=submit], .mega-menu[data-color='light-green-invert'] .drop-down-tab-bar button[type=reset] {
	background: #7eb53d
}

.mega-menu[data-color='light-green-invert'] .drop-down input[type="submit"]:hover, .mega-menu[data-color='light-green-invert'] .drop-down input[type="button"]:hover, .mega-menu[data-color='light-green-invert'] .drop-down button[type=submit]:hover, .mega-menu[data-color='light-green-invert'] .drop-down button[type=reset]:hover, .mega-menu[data-color='light-green-invert'] .drop-down-tab-bar input[type="submit"]:hover, .mega-menu[data-color='light-green-invert'] .drop-down-tab-bar input[type="button"]:hover, .mega-menu[data-color='light-green-invert'] .drop-down-tab-bar button[type=submit]:hover, .mega-menu[data-color='light-green-invert'] .drop-down-tab-bar button[type=reset]:hover {
	background-color: #8bc24a
}

.mega-menu[data-color='light-green-invert'] .drop-down a:hover, .mega-menu[data-color='light-green-invert'] .drop-down-tab-bar a:hover {
	color: #7eb53d
}

.mega-menu[data-color='light-green-invert'] .drop-down::-moz-selection, .mega-menu[data-color='light-green-invert'] .drop-down-tab-bar::-moz-selection {
	background: #b0d583
}

.mega-menu[data-color='light-green-invert'] .drop-down::selection, .mega-menu[data-color='light-green-invert'] .drop-down-tab-bar::selection {
	background: #b0d583
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='light-green-invert'].vertical-left .menu-search-bar input, .mega-menu[data-color='light-green-invert'].vertical-left .menu-search-bar li, .mega-menu[data-color='light-green-invert'].vertical-left .menu-search-bar form, .mega-menu[data-color='light-green-invert'].vertical-left .menu-search-bar label, .mega-menu[data-color='light-green-invert'].vertical-right .menu-search-bar input, .mega-menu[data-color='light-green-invert'].vertical-right .menu-search-bar li, .mega-menu[data-color='light-green-invert'].vertical-right .menu-search-bar form, .mega-menu[data-color='light-green-invert'].vertical-right .menu-search-bar label {
		background: #7eb53d
	}

}

.mega-menu[data-color='lime'] {
}

.mega-menu[data-color='lime'] > section.menu-list-items {
	background-color: #333
}

.mega-menu[data-color='lime'] .menu-logo > li > a:hover {
	background-color: #b2c022
}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='lime'] .menu-links > li.activeTriggerMobile {
		background-color: #b2c022
	}

}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='lime'] .menu-links > li.activeTrigger {
		background-color: #b2c022
	}

}

.mega-menu[data-color='lime'] .menu-links > li.active {
	background-color: #b2c022
}

.mega-menu[data-color='lime'] .menu-links > li:hover {
	background-color: #b2c022
}

.mega-menu[data-color='lime'] .menu-search-bar li:hover i.fas.fa-search {
	background: #b2c022
}

.mega-menu[data-color='lime'] .menu-search-bar input:focus {
	background: #b2c022
}

.mega-menu[data-color='lime'] .menu-mobile-collapse-trigger {
	background: #1a1a1a
}

.mega-menu[data-color='lime'] .menu-mobile-collapse-trigger:hover {
	background: black
}

.mega-menu[data-color='lime'] .drop-down-multilevel li:hover {
	background: #b2c022
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='lime'] .drop-down-multilevel li.activeTrigger {
		background: #b2c022
	}

}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='lime'] .drop-down-multilevel li.activeTriggerMobile {
		background: #b2c022
	}

}

.mega-menu[data-color='lime'] .drop-down input[type="submit"], .mega-menu[data-color='lime'] .drop-down input[type="button"], .mega-menu[data-color='lime'] .drop-down button[type=submit], .mega-menu[data-color='lime'] .drop-down button[type=reset], .mega-menu[data-color='lime'] .drop-down-tab-bar input[type="submit"], .mega-menu[data-color='lime'] .drop-down-tab-bar input[type="button"], .mega-menu[data-color='lime'] .drop-down-tab-bar button[type=submit], .mega-menu[data-color='lime'] .drop-down-tab-bar button[type=reset] {
	background: #b2c022
}

.mega-menu[data-color='lime'] .drop-down input[type="submit"]:hover, .mega-menu[data-color='lime'] .drop-down input[type="button"]:hover, .mega-menu[data-color='lime'] .drop-down button[type=submit]:hover, .mega-menu[data-color='lime'] .drop-down button[type=reset]:hover, .mega-menu[data-color='lime'] .drop-down-tab-bar input[type="submit"]:hover, .mega-menu[data-color='lime'] .drop-down-tab-bar input[type="button"]:hover, .mega-menu[data-color='lime'] .drop-down-tab-bar button[type=submit]:hover, .mega-menu[data-color='lime'] .drop-down-tab-bar button[type=reset]:hover {
	background-color: #333
}

.mega-menu[data-color='lime'] .drop-down a:hover, .mega-menu[data-color='lime'] .drop-down-tab-bar a:hover {
	color: #b2c022
}

.mega-menu[data-color='lime'] .drop-down::-moz-selection, .mega-menu[data-color='lime'] .drop-down-tab-bar::-moz-selection {
	background: #d8e464
}

.mega-menu[data-color='lime'] .drop-down::selection, .mega-menu[data-color='lime'] .drop-down-tab-bar::selection {
	background: #d8e464
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='lime'].vertical-left .menu-search-bar input, .mega-menu[data-color='lime'].vertical-left .menu-search-bar li, .mega-menu[data-color='lime'].vertical-left .menu-search-bar form, .mega-menu[data-color='lime'].vertical-left .menu-search-bar label, .mega-menu[data-color='lime'].vertical-right .menu-search-bar input, .mega-menu[data-color='lime'].vertical-right .menu-search-bar li, .mega-menu[data-color='lime'].vertical-right .menu-search-bar form, .mega-menu[data-color='lime'].vertical-right .menu-search-bar label {
		background: #b2c022
	}

}

.mega-menu[data-color='lime-invert'] {
}

.mega-menu[data-color='lime-invert'] > section.menu-list-items {
	background-color: #b2c022
}

.mega-menu[data-color='lime-invert'] .menu-logo > li > a:hover {
	background-color: #9daa1e
}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='lime-invert'] .menu-links > li.activeTriggerMobile {
		background-color: #9daa1e
	}

}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='lime-invert'] .menu-links > li.activeTrigger {
		background-color: #9daa1e
	}

}

.mega-menu[data-color='lime-invert'] .menu-links > li.active {
	background-color: #9daa1e
}

.mega-menu[data-color='lime-invert'] .menu-links > li:hover {
	background-color: #9daa1e
}

.mega-menu[data-color='lime-invert'] .menu-search-bar li:hover i.fas.fa-search {
	background: #9daa1e
}

.mega-menu[data-color='lime-invert'] .menu-search-bar input:focus {
	background: #9daa1e
}

.mega-menu[data-color='lime-invert'] .menu-mobile-collapse-trigger {
	background: #89951a
}

.mega-menu[data-color='lime-invert'] .menu-mobile-collapse-trigger:hover {
	background: #616913
}

.mega-menu[data-color='lime-invert'] .drop-down-multilevel li:hover {
	background: #9daa1e
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='lime-invert'] .drop-down-multilevel li.activeTrigger {
		background: #9daa1e
	}

}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='lime-invert'] .drop-down-multilevel li.activeTriggerMobile {
		background: #9daa1e
	}

}

.mega-menu[data-color='lime-invert'] .drop-down input[type="submit"], .mega-menu[data-color='lime-invert'] .drop-down input[type="button"], .mega-menu[data-color='lime-invert'] .drop-down button[type=submit], .mega-menu[data-color='lime-invert'] .drop-down button[type=reset], .mega-menu[data-color='lime-invert'] .drop-down-tab-bar input[type="submit"], .mega-menu[data-color='lime-invert'] .drop-down-tab-bar input[type="button"], .mega-menu[data-color='lime-invert'] .drop-down-tab-bar button[type=submit], .mega-menu[data-color='lime-invert'] .drop-down-tab-bar button[type=reset] {
	background: #9daa1e
}

.mega-menu[data-color='lime-invert'] .drop-down input[type="submit"]:hover, .mega-menu[data-color='lime-invert'] .drop-down input[type="button"]:hover, .mega-menu[data-color='lime-invert'] .drop-down button[type=submit]:hover, .mega-menu[data-color='lime-invert'] .drop-down button[type=reset]:hover, .mega-menu[data-color='lime-invert'] .drop-down-tab-bar input[type="submit"]:hover, .mega-menu[data-color='lime-invert'] .drop-down-tab-bar input[type="button"]:hover, .mega-menu[data-color='lime-invert'] .drop-down-tab-bar button[type=submit]:hover, .mega-menu[data-color='lime-invert'] .drop-down-tab-bar button[type=reset]:hover {
	background-color: #b2c022
}

.mega-menu[data-color='lime-invert'] .drop-down a:hover, .mega-menu[data-color='lime-invert'] .drop-down-tab-bar a:hover {
	color: #9daa1e
}

.mega-menu[data-color='lime-invert'] .drop-down::-moz-selection, .mega-menu[data-color='lime-invert'] .drop-down-tab-bar::-moz-selection {
	background: #d2e04f
}

.mega-menu[data-color='lime-invert'] .drop-down::selection, .mega-menu[data-color='lime-invert'] .drop-down-tab-bar::selection {
	background: #d2e04f
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='lime-invert'].vertical-left .menu-search-bar input, .mega-menu[data-color='lime-invert'].vertical-left .menu-search-bar li, .mega-menu[data-color='lime-invert'].vertical-left .menu-search-bar form, .mega-menu[data-color='lime-invert'].vertical-left .menu-search-bar label, .mega-menu[data-color='lime-invert'].vertical-right .menu-search-bar input, .mega-menu[data-color='lime-invert'].vertical-right .menu-search-bar li, .mega-menu[data-color='lime-invert'].vertical-right .menu-search-bar form, .mega-menu[data-color='lime-invert'].vertical-right .menu-search-bar label {
		background: #9daa1e
	}

}

.mega-menu[data-color='orange'] {
}

.mega-menu[data-color='orange'] > section.menu-list-items {
	background-color: #333
}

.mega-menu[data-color='orange'] .menu-logo > li > a:hover {
	background-color: #ff9700
}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='orange'] .menu-links > li.activeTriggerMobile {
		background-color: #ff9700
	}

}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='orange'] .menu-links > li.activeTrigger {
		background-color: #ff9700
	}

}

.mega-menu[data-color='orange'] .menu-links > li.active {
	background-color: #ff9700
}

.mega-menu[data-color='orange'] .menu-links > li:hover {
	background-color: #ff9700
}

.mega-menu[data-color='orange'] .menu-search-bar li:hover i.fas.fa-search {
	background: #ff9700
}

.mega-menu[data-color='orange'] .menu-search-bar input:focus {
	background: #ff9700
}

.mega-menu[data-color='orange'] .menu-mobile-collapse-trigger {
	background: #1a1a1a
}

.mega-menu[data-color='orange'] .menu-mobile-collapse-trigger:hover {
	background: black
}

.mega-menu[data-color='orange'] .drop-down-multilevel li:hover {
	background: #ff9700
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='orange'] .drop-down-multilevel li.activeTrigger {
		background: #ff9700
	}

}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='orange'] .drop-down-multilevel li.activeTriggerMobile {
		background: #ff9700
	}

}

.mega-menu[data-color='orange'] .drop-down input[type="submit"], .mega-menu[data-color='orange'] .drop-down input[type="button"], .mega-menu[data-color='orange'] .drop-down button[type=submit], .mega-menu[data-color='orange'] .drop-down button[type=reset], .mega-menu[data-color='orange'] .drop-down-tab-bar input[type="submit"], .mega-menu[data-color='orange'] .drop-down-tab-bar input[type="button"], .mega-menu[data-color='orange'] .drop-down-tab-bar button[type=submit], .mega-menu[data-color='orange'] .drop-down-tab-bar button[type=reset] {
	background: #ff9700
}

.mega-menu[data-color='orange'] .drop-down input[type="submit"]:hover, .mega-menu[data-color='orange'] .drop-down input[type="button"]:hover, .mega-menu[data-color='orange'] .drop-down button[type=submit]:hover, .mega-menu[data-color='orange'] .drop-down button[type=reset]:hover, .mega-menu[data-color='orange'] .drop-down-tab-bar input[type="submit"]:hover, .mega-menu[data-color='orange'] .drop-down-tab-bar input[type="button"]:hover, .mega-menu[data-color='orange'] .drop-down-tab-bar button[type=submit]:hover, .mega-menu[data-color='orange'] .drop-down-tab-bar button[type=reset]:hover {
	background-color: #333
}

.mega-menu[data-color='orange'] .drop-down a:hover, .mega-menu[data-color='orange'] .drop-down-tab-bar a:hover {
	color: #ff9700
}

.mega-menu[data-color='orange'] .drop-down::-moz-selection, .mega-menu[data-color='orange'] .drop-down-tab-bar::-moz-selection {
	background: #ffc166
}

.mega-menu[data-color='orange'] .drop-down::selection, .mega-menu[data-color='orange'] .drop-down-tab-bar::selection {
	background: #ffc166
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='orange'].vertical-left .menu-search-bar input, .mega-menu[data-color='orange'].vertical-left .menu-search-bar li, .mega-menu[data-color='orange'].vertical-left .menu-search-bar form, .mega-menu[data-color='orange'].vertical-left .menu-search-bar label, .mega-menu[data-color='orange'].vertical-right .menu-search-bar input, .mega-menu[data-color='orange'].vertical-right .menu-search-bar li, .mega-menu[data-color='orange'].vertical-right .menu-search-bar form, .mega-menu[data-color='orange'].vertical-right .menu-search-bar label {
		background: #ff9700
	}

}

.mega-menu[data-color='orange-invert'] {
}

.mega-menu[data-color='orange-invert'] > section.menu-list-items {
	background-color: #ff9700
}

.mega-menu[data-color='orange-invert'] .menu-logo > li > a:hover {
	background-color: #eb8b00
}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='orange-invert'] .menu-links > li.activeTriggerMobile {
		background-color: #eb8b00
	}

}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='orange-invert'] .menu-links > li.activeTrigger {
		background-color: #eb8b00
	}

}

.mega-menu[data-color='orange-invert'] .menu-links > li.active {
	background-color: #eb8b00
}

.mega-menu[data-color='orange-invert'] .menu-links > li:hover {
	background-color: #eb8b00
}

.mega-menu[data-color='orange-invert'] .menu-search-bar li:hover i.fas.fa-search {
	background: #eb8b00
}

.mega-menu[data-color='orange-invert'] .menu-search-bar input:focus {
	background: #eb8b00
}

.mega-menu[data-color='orange-invert'] .menu-mobile-collapse-trigger {
	background: #cc7900
}

.mega-menu[data-color='orange-invert'] .menu-mobile-collapse-trigger:hover {
	background: #995b00
}

.mega-menu[data-color='orange-invert'] .drop-down-multilevel li:hover {
	background: #eb8b00
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='orange-invert'] .drop-down-multilevel li.activeTrigger {
		background: #eb8b00
	}

}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='orange-invert'] .drop-down-multilevel li.activeTriggerMobile {
		background: #eb8b00
	}

}

.mega-menu[data-color='orange-invert'] .drop-down input[type="submit"], .mega-menu[data-color='orange-invert'] .drop-down input[type="button"], .mega-menu[data-color='orange-invert'] .drop-down button[type=submit], .mega-menu[data-color='orange-invert'] .drop-down button[type=reset], .mega-menu[data-color='orange-invert'] .drop-down-tab-bar input[type="submit"], .mega-menu[data-color='orange-invert'] .drop-down-tab-bar input[type="button"], .mega-menu[data-color='orange-invert'] .drop-down-tab-bar button[type=submit], .mega-menu[data-color='orange-invert'] .drop-down-tab-bar button[type=reset] {
	background: #eb8b00
}

.mega-menu[data-color='orange-invert'] .drop-down input[type="submit"]:hover, .mega-menu[data-color='orange-invert'] .drop-down input[type="button"]:hover, .mega-menu[data-color='orange-invert'] .drop-down button[type=submit]:hover, .mega-menu[data-color='orange-invert'] .drop-down button[type=reset]:hover, .mega-menu[data-color='orange-invert'] .drop-down-tab-bar input[type="submit"]:hover, .mega-menu[data-color='orange-invert'] .drop-down-tab-bar input[type="button"]:hover, .mega-menu[data-color='orange-invert'] .drop-down-tab-bar button[type=submit]:hover, .mega-menu[data-color='orange-invert'] .drop-down-tab-bar button[type=reset]:hover {
	background-color: #ff9700
}

.mega-menu[data-color='orange-invert'] .drop-down a:hover, .mega-menu[data-color='orange-invert'] .drop-down-tab-bar a:hover {
	color: #eb8b00
}

.mega-menu[data-color='orange-invert'] .drop-down::-moz-selection, .mega-menu[data-color='orange-invert'] .drop-down-tab-bar::-moz-selection {
	background: #ffb852
}

.mega-menu[data-color='orange-invert'] .drop-down::selection, .mega-menu[data-color='orange-invert'] .drop-down-tab-bar::selection {
	background: #ffb852
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='orange-invert'].vertical-left .menu-search-bar input, .mega-menu[data-color='orange-invert'].vertical-left .menu-search-bar li, .mega-menu[data-color='orange-invert'].vertical-left .menu-search-bar form, .mega-menu[data-color='orange-invert'].vertical-left .menu-search-bar label, .mega-menu[data-color='orange-invert'].vertical-right .menu-search-bar input, .mega-menu[data-color='orange-invert'].vertical-right .menu-search-bar li, .mega-menu[data-color='orange-invert'].vertical-right .menu-search-bar form, .mega-menu[data-color='orange-invert'].vertical-right .menu-search-bar label {
		background: #eb8b00
	}

}

.mega-menu[data-color='pink'] {
}

.mega-menu[data-color='pink'] > section.menu-list-items {
	background-color: #333
}

.mega-menu[data-color='pink'] .menu-logo > li > a:hover {
	background-color: #ea1e63
}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='pink'] .menu-links > li.activeTriggerMobile {
		background-color: #ea1e63
	}

}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='pink'] .menu-links > li.activeTrigger {
		background-color: #ea1e63
	}

}

.mega-menu[data-color='pink'] .menu-links > li.active {
	background-color: #ea1e63
}

.mega-menu[data-color='pink'] .menu-links > li:hover {
	background-color: #ea1e63
}

.mega-menu[data-color='pink'] .menu-search-bar li:hover i.fas.fa-search {
	background: #ea1e63
}

.mega-menu[data-color='pink'] .menu-search-bar input:focus {
	background: #ea1e63
}

.mega-menu[data-color='pink'] .menu-mobile-collapse-trigger {
	background: #1a1a1a
}

.mega-menu[data-color='pink'] .menu-mobile-collapse-trigger:hover {
	background: black
}

.mega-menu[data-color='pink'] .drop-down-multilevel li:hover {
	background: #ea1e63
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='pink'] .drop-down-multilevel li.activeTrigger {
		background: #ea1e63
	}

}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='pink'] .drop-down-multilevel li.activeTriggerMobile {
		background: #ea1e63
	}

}

.mega-menu[data-color='pink'] .drop-down input[type="submit"], .mega-menu[data-color='pink'] .drop-down input[type="button"], .mega-menu[data-color='pink'] .drop-down button[type=submit], .mega-menu[data-color='pink'] .drop-down button[type=reset], .mega-menu[data-color='pink'] .drop-down-tab-bar input[type="submit"], .mega-menu[data-color='pink'] .drop-down-tab-bar input[type="button"], .mega-menu[data-color='pink'] .drop-down-tab-bar button[type=submit], .mega-menu[data-color='pink'] .drop-down-tab-bar button[type=reset] {
	background: #ea1e63
}

.mega-menu[data-color='pink'] .drop-down input[type="submit"]:hover, .mega-menu[data-color='pink'] .drop-down input[type="button"]:hover, .mega-menu[data-color='pink'] .drop-down button[type=submit]:hover, .mega-menu[data-color='pink'] .drop-down button[type=reset]:hover, .mega-menu[data-color='pink'] .drop-down-tab-bar input[type="submit"]:hover, .mega-menu[data-color='pink'] .drop-down-tab-bar input[type="button"]:hover, .mega-menu[data-color='pink'] .drop-down-tab-bar button[type=submit]:hover, .mega-menu[data-color='pink'] .drop-down-tab-bar button[type=reset]:hover {
	background-color: #333
}

.mega-menu[data-color='pink'] .drop-down a:hover, .mega-menu[data-color='pink'] .drop-down-tab-bar a:hover {
	color: #ea1e63
}

.mega-menu[data-color='pink'] .drop-down::-moz-selection, .mega-menu[data-color='pink'] .drop-down-tab-bar::-moz-selection {
	background: #f37ba4
}

.mega-menu[data-color='pink'] .drop-down::selection, .mega-menu[data-color='pink'] .drop-down-tab-bar::selection {
	background: #f37ba4
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='pink'].vertical-left .menu-search-bar input, .mega-menu[data-color='pink'].vertical-left .menu-search-bar li, .mega-menu[data-color='pink'].vertical-left .menu-search-bar form, .mega-menu[data-color='pink'].vertical-left .menu-search-bar label, .mega-menu[data-color='pink'].vertical-right .menu-search-bar input, .mega-menu[data-color='pink'].vertical-right .menu-search-bar li, .mega-menu[data-color='pink'].vertical-right .menu-search-bar form, .mega-menu[data-color='pink'].vertical-right .menu-search-bar label {
		background: #ea1e63
	}

}

.mega-menu[data-color='pink-invert'] {
}

.mega-menu[data-color='pink-invert'] > section.menu-list-items {
	background-color: #ec3573
}

.mega-menu[data-color='pink-invert'] .menu-logo > li > a:hover {
	background-color: #da1457
}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='pink-invert'] .menu-links > li.activeTriggerMobile {
		background-color: #da1457
	}

}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='pink-invert'] .menu-links > li.activeTrigger {
		background-color: #da1457
	}

}

.mega-menu[data-color='pink-invert'] .menu-links > li.active {
	background-color: #da1457
}

.mega-menu[data-color='pink-invert'] .menu-links > li:hover {
	background-color: #da1457
}

.mega-menu[data-color='pink-invert'] .menu-search-bar li:hover i.fas.fa-search {
	background: #da1457
}

.mega-menu[data-color='pink-invert'] .menu-search-bar input:focus {
	background: #da1457
}

.mega-menu[data-color='pink-invert'] .menu-mobile-collapse-trigger {
	background: #da1457
}

.mega-menu[data-color='pink-invert'] .menu-mobile-collapse-trigger:hover {
	background: #ab1045
}

.mega-menu[data-color='pink-invert'] .drop-down-multilevel li:hover {
	background: #da1457
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='pink-invert'] .drop-down-multilevel li.activeTrigger {
		background: #da1457
	}

}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='pink-invert'] .drop-down-multilevel li.activeTriggerMobile {
		background: #da1457
	}

}

.mega-menu[data-color='pink-invert'] .drop-down input[type="submit"], .mega-menu[data-color='pink-invert'] .drop-down input[type="button"], .mega-menu[data-color='pink-invert'] .drop-down button[type=submit], .mega-menu[data-color='pink-invert'] .drop-down button[type=reset], .mega-menu[data-color='pink-invert'] .drop-down-tab-bar input[type="submit"], .mega-menu[data-color='pink-invert'] .drop-down-tab-bar input[type="button"], .mega-menu[data-color='pink-invert'] .drop-down-tab-bar button[type=submit], .mega-menu[data-color='pink-invert'] .drop-down-tab-bar button[type=reset] {
	background: #da1457
}

.mega-menu[data-color='pink-invert'] .drop-down input[type="submit"]:hover, .mega-menu[data-color='pink-invert'] .drop-down input[type="button"]:hover, .mega-menu[data-color='pink-invert'] .drop-down button[type=submit]:hover, .mega-menu[data-color='pink-invert'] .drop-down button[type=reset]:hover, .mega-menu[data-color='pink-invert'] .drop-down-tab-bar input[type="submit"]:hover, .mega-menu[data-color='pink-invert'] .drop-down-tab-bar input[type="button"]:hover, .mega-menu[data-color='pink-invert'] .drop-down-tab-bar button[type=submit]:hover, .mega-menu[data-color='pink-invert'] .drop-down-tab-bar button[type=reset]:hover {
	background-color: #ec3573
}

.mega-menu[data-color='pink-invert'] .drop-down a:hover, .mega-menu[data-color='pink-invert'] .drop-down-tab-bar a:hover {
	color: #da1457
}

.mega-menu[data-color='pink-invert'] .drop-down::-moz-selection, .mega-menu[data-color='pink-invert'] .drop-down-tab-bar::-moz-selection {
	background: #f16494
}

.mega-menu[data-color='pink-invert'] .drop-down::selection, .mega-menu[data-color='pink-invert'] .drop-down-tab-bar::selection {
	background: #f16494
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='pink-invert'].vertical-left .menu-search-bar input, .mega-menu[data-color='pink-invert'].vertical-left .menu-search-bar li, .mega-menu[data-color='pink-invert'].vertical-left .menu-search-bar form, .mega-menu[data-color='pink-invert'].vertical-left .menu-search-bar label, .mega-menu[data-color='pink-invert'].vertical-right .menu-search-bar input, .mega-menu[data-color='pink-invert'].vertical-right .menu-search-bar li, .mega-menu[data-color='pink-invert'].vertical-right .menu-search-bar form, .mega-menu[data-color='pink-invert'].vertical-right .menu-search-bar label {
		background: #da1457
	}

}

.mega-menu[data-color='purple'] {
}

.mega-menu[data-color='purple'] > section.menu-list-items {
	background-color: #333
}

.mega-menu[data-color='purple'] .menu-logo > li > a:hover {
	background-color: #9c28b1
}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='purple'] .menu-links > li.activeTriggerMobile {
		background-color: #9c28b1
	}

}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='purple'] .menu-links > li.activeTrigger {
		background-color: #9c28b1
	}

}

.mega-menu[data-color='purple'] .menu-links > li.active {
	background-color: #9c28b1
}

.mega-menu[data-color='purple'] .menu-links > li:hover {
	background-color: #9c28b1
}

.mega-menu[data-color='purple'] .menu-search-bar li:hover i.fas.fa-search {
	background: #9c28b1
}

.mega-menu[data-color='purple'] .menu-search-bar input:focus {
	background: #9c28b1
}

.mega-menu[data-color='purple'] .menu-mobile-collapse-trigger {
	background: #1a1a1a
}

.mega-menu[data-color='purple'] .menu-mobile-collapse-trigger:hover {
	background: black
}

.mega-menu[data-color='purple'] .drop-down-multilevel li:hover {
	background: #9c28b1
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='purple'] .drop-down-multilevel li.activeTrigger {
		background: #9c28b1
	}

}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='purple'] .drop-down-multilevel li.activeTriggerMobile {
		background: #9c28b1
	}

}

.mega-menu[data-color='purple'] .drop-down input[type="submit"], .mega-menu[data-color='purple'] .drop-down input[type="button"], .mega-menu[data-color='purple'] .drop-down button[type=submit], .mega-menu[data-color='purple'] .drop-down button[type=reset], .mega-menu[data-color='purple'] .drop-down-tab-bar input[type="submit"], .mega-menu[data-color='purple'] .drop-down-tab-bar input[type="button"], .mega-menu[data-color='purple'] .drop-down-tab-bar button[type=submit], .mega-menu[data-color='purple'] .drop-down-tab-bar button[type=reset] {
	background: #9c28b1
}

.mega-menu[data-color='purple'] .drop-down input[type="submit"]:hover, .mega-menu[data-color='purple'] .drop-down input[type="button"]:hover, .mega-menu[data-color='purple'] .drop-down button[type=submit]:hover, .mega-menu[data-color='purple'] .drop-down button[type=reset]:hover, .mega-menu[data-color='purple'] .drop-down-tab-bar input[type="submit"]:hover, .mega-menu[data-color='purple'] .drop-down-tab-bar input[type="button"]:hover, .mega-menu[data-color='purple'] .drop-down-tab-bar button[type=submit]:hover, .mega-menu[data-color='purple'] .drop-down-tab-bar button[type=reset]:hover {
	background-color: #333
}

.mega-menu[data-color='purple'] .drop-down a:hover, .mega-menu[data-color='purple'] .drop-down-tab-bar a:hover {
	color: #9c28b1
}

.mega-menu[data-color='purple'] .drop-down::-moz-selection, .mega-menu[data-color='purple'] .drop-down-tab-bar::-moz-selection {
	background: #c963dc
}

.mega-menu[data-color='purple'] .drop-down::selection, .mega-menu[data-color='purple'] .drop-down-tab-bar::selection {
	background: #c963dc
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='purple'].vertical-left .menu-search-bar input, .mega-menu[data-color='purple'].vertical-left .menu-search-bar li, .mega-menu[data-color='purple'].vertical-left .menu-search-bar form, .mega-menu[data-color='purple'].vertical-left .menu-search-bar label, .mega-menu[data-color='purple'].vertical-right .menu-search-bar input, .mega-menu[data-color='purple'].vertical-right .menu-search-bar li, .mega-menu[data-color='purple'].vertical-right .menu-search-bar form, .mega-menu[data-color='purple'].vertical-right .menu-search-bar label {
		background: #9c28b1
	}

}

.mega-menu[data-color='purple-invert'] {
}

.mega-menu[data-color='purple-invert'] > section.menu-list-items {
	background-color: #ae2dc6
}

.mega-menu[data-color='purple-invert'] .menu-logo > li > a:hover {
	background-color: #9125a5
}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='purple-invert'] .menu-links > li.activeTriggerMobile {
		background-color: #9125a5
	}

}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='purple-invert'] .menu-links > li.activeTrigger {
		background-color: #9125a5
	}

}

.mega-menu[data-color='purple-invert'] .menu-links > li.active {
	background-color: #9125a5
}

.mega-menu[data-color='purple-invert'] .menu-links > li:hover {
	background-color: #9125a5
}

.mega-menu[data-color='purple-invert'] .menu-search-bar li:hover i.fas.fa-search {
	background: #9125a5
}

.mega-menu[data-color='purple-invert'] .menu-search-bar input:focus {
	background: #9125a5
}

.mega-menu[data-color='purple-invert'] .menu-mobile-collapse-trigger {
	background: #8a239c
}

.mega-menu[data-color='purple-invert'] .menu-mobile-collapse-trigger:hover {
	background: #651a73
}

.mega-menu[data-color='purple-invert'] .drop-down-multilevel li:hover {
	background: #9125a5
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='purple-invert'] .drop-down-multilevel li.activeTrigger {
		background: #9125a5
	}

}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='purple-invert'] .drop-down-multilevel li.activeTriggerMobile {
		background: #9125a5
	}

}

.mega-menu[data-color='purple-invert'] .drop-down input[type="submit"], .mega-menu[data-color='purple-invert'] .drop-down input[type="button"], .mega-menu[data-color='purple-invert'] .drop-down button[type=submit], .mega-menu[data-color='purple-invert'] .drop-down button[type=reset], .mega-menu[data-color='purple-invert'] .drop-down-tab-bar input[type="submit"], .mega-menu[data-color='purple-invert'] .drop-down-tab-bar input[type="button"], .mega-menu[data-color='purple-invert'] .drop-down-tab-bar button[type=submit], .mega-menu[data-color='purple-invert'] .drop-down-tab-bar button[type=reset] {
	background: #9125a5
}

.mega-menu[data-color='purple-invert'] .drop-down input[type="submit"]:hover, .mega-menu[data-color='purple-invert'] .drop-down input[type="button"]:hover, .mega-menu[data-color='purple-invert'] .drop-down button[type=submit]:hover, .mega-menu[data-color='purple-invert'] .drop-down button[type=reset]:hover, .mega-menu[data-color='purple-invert'] .drop-down-tab-bar input[type="submit"]:hover, .mega-menu[data-color='purple-invert'] .drop-down-tab-bar input[type="button"]:hover, .mega-menu[data-color='purple-invert'] .drop-down-tab-bar button[type=submit]:hover, .mega-menu[data-color='purple-invert'] .drop-down-tab-bar button[type=reset]:hover {
	background-color: #ae2dc6
}

.mega-menu[data-color='purple-invert'] .drop-down a:hover, .mega-menu[data-color='purple-invert'] .drop-down-tab-bar a:hover {
	color: #9125a5
}

.mega-menu[data-color='purple-invert'] .drop-down::-moz-selection, .mega-menu[data-color='purple-invert'] .drop-down-tab-bar::-moz-selection {
	background: #c557d9
}

.mega-menu[data-color='purple-invert'] .drop-down::selection, .mega-menu[data-color='purple-invert'] .drop-down-tab-bar::selection {
	background: #c557d9
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='purple-invert'].vertical-left .menu-search-bar input, .mega-menu[data-color='purple-invert'].vertical-left .menu-search-bar li, .mega-menu[data-color='purple-invert'].vertical-left .menu-search-bar form, .mega-menu[data-color='purple-invert'].vertical-left .menu-search-bar label, .mega-menu[data-color='purple-invert'].vertical-right .menu-search-bar input, .mega-menu[data-color='purple-invert'].vertical-right .menu-search-bar li, .mega-menu[data-color='purple-invert'].vertical-right .menu-search-bar form, .mega-menu[data-color='purple-invert'].vertical-right .menu-search-bar label {
		background: #9125a5
	}

}

.mega-menu[data-color='red'] {
}

.mega-menu[data-color='red'] > section.menu-list-items {
	background-color: #333
}

.mega-menu[data-color='red'] .menu-logo > li > a:hover {
	background-color: #f44236
}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='red'] .menu-links > li.activeTriggerMobile {
		background-color: #f44236
	}

}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='red'] .menu-links > li.activeTrigger {
		background-color: #f44236
	}

}

.mega-menu[data-color='red'] .menu-links > li.active {
	background-color: #f44236
}

.mega-menu[data-color='red'] .menu-links > li:hover {
	background-color: #f44236
}

.mega-menu[data-color='red'] .menu-search-bar li:hover i.fas.fa-search {
	background: #f44236
}

.mega-menu[data-color='red'] .menu-search-bar input:focus {
	background: #f44236
}

.mega-menu[data-color='red'] .menu-mobile-collapse-trigger {
	background: #1a1a1a
}

.mega-menu[data-color='red'] .menu-mobile-collapse-trigger:hover {
	background: black
}

.mega-menu[data-color='red'] .drop-down-multilevel li:hover {
	background: #f44236
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='red'] .drop-down-multilevel li.activeTrigger {
		background: #f44236
	}

}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='red'] .drop-down-multilevel li.activeTriggerMobile {
		background: #f44236
	}

}

.mega-menu[data-color='red'] .drop-down input[type="submit"], .mega-menu[data-color='red'] .drop-down input[type="button"], .mega-menu[data-color='red'] .drop-down button[type=submit], .mega-menu[data-color='red'] .drop-down button[type=reset], .mega-menu[data-color='red'] .drop-down-tab-bar input[type="submit"], .mega-menu[data-color='red'] .drop-down-tab-bar input[type="button"], .mega-menu[data-color='red'] .drop-down-tab-bar button[type=submit], .mega-menu[data-color='red'] .drop-down-tab-bar button[type=reset] {
	background: #f44236
}

.mega-menu[data-color='red'] .drop-down input[type="submit"]:hover, .mega-menu[data-color='red'] .drop-down input[type="button"]:hover, .mega-menu[data-color='red'] .drop-down button[type=submit]:hover, .mega-menu[data-color='red'] .drop-down button[type=reset]:hover, .mega-menu[data-color='red'] .drop-down-tab-bar input[type="submit"]:hover, .mega-menu[data-color='red'] .drop-down-tab-bar input[type="button"]:hover, .mega-menu[data-color='red'] .drop-down-tab-bar button[type=submit]:hover, .mega-menu[data-color='red'] .drop-down-tab-bar button[type=reset]:hover {
	background-color: #333
}

.mega-menu[data-color='red'] .drop-down a:hover, .mega-menu[data-color='red'] .drop-down-tab-bar a:hover {
	color: #f44236
}

.mega-menu[data-color='red'] .drop-down::-moz-selection, .mega-menu[data-color='red'] .drop-down-tab-bar::-moz-selection {
	background: #f99d97
}

.mega-menu[data-color='red'] .drop-down::selection, .mega-menu[data-color='red'] .drop-down-tab-bar::selection {
	background: #f99d97
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='red'].vertical-left .menu-search-bar input, .mega-menu[data-color='red'].vertical-left .menu-search-bar li, .mega-menu[data-color='red'].vertical-left .menu-search-bar form, .mega-menu[data-color='red'].vertical-left .menu-search-bar label, .mega-menu[data-color='red'].vertical-right .menu-search-bar input, .mega-menu[data-color='red'].vertical-right .menu-search-bar li, .mega-menu[data-color='red'].vertical-right .menu-search-bar form, .mega-menu[data-color='red'].vertical-right .menu-search-bar label {
		background: #f44236
	}

}

.mega-menu[data-color='red-invert'] {
}

.mega-menu[data-color='red-invert'] > section.menu-list-items {
	background-color: #f5594e
}

.mega-menu[data-color='red-invert'] .menu-logo > li > a:hover {
	background-color: #f32b1e
}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='red-invert'] .menu-links > li.activeTriggerMobile {
		background-color: #f32b1e
	}

}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='red-invert'] .menu-links > li.activeTrigger {
		background-color: #f32b1e
	}

}

.mega-menu[data-color='red-invert'] .menu-links > li.active {
	background-color: #f32b1e
}

.mega-menu[data-color='red-invert'] .menu-links > li:hover {
	background-color: #f32b1e
}

.mega-menu[data-color='red-invert'] .menu-search-bar li:hover i.fas.fa-search {
	background: #f32b1e
}

.mega-menu[data-color='red-invert'] .menu-search-bar input:focus {
	background: #f32b1e
}

.mega-menu[data-color='red-invert'] .menu-mobile-collapse-trigger {
	background: #f32b1e
}

.mega-menu[data-color='red-invert'] .menu-mobile-collapse-trigger:hover {
	background: #d2180b
}

.mega-menu[data-color='red-invert'] .drop-down-multilevel li:hover {
	background: #f32b1e
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='red-invert'] .drop-down-multilevel li.activeTrigger {
		background: #f32b1e
	}

}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='red-invert'] .drop-down-multilevel li.activeTriggerMobile {
		background: #f32b1e
	}

}

.mega-menu[data-color='red-invert'] .drop-down input[type="submit"], .mega-menu[data-color='red-invert'] .drop-down input[type="button"], .mega-menu[data-color='red-invert'] .drop-down button[type=submit], .mega-menu[data-color='red-invert'] .drop-down button[type=reset], .mega-menu[data-color='red-invert'] .drop-down-tab-bar input[type="submit"], .mega-menu[data-color='red-invert'] .drop-down-tab-bar input[type="button"], .mega-menu[data-color='red-invert'] .drop-down-tab-bar button[type=submit], .mega-menu[data-color='red-invert'] .drop-down-tab-bar button[type=reset] {
	background: #f32b1e
}

.mega-menu[data-color='red-invert'] .drop-down input[type="submit"]:hover, .mega-menu[data-color='red-invert'] .drop-down input[type="button"]:hover, .mega-menu[data-color='red-invert'] .drop-down button[type=submit]:hover, .mega-menu[data-color='red-invert'] .drop-down button[type=reset]:hover, .mega-menu[data-color='red-invert'] .drop-down-tab-bar input[type="submit"]:hover, .mega-menu[data-color='red-invert'] .drop-down-tab-bar input[type="button"]:hover, .mega-menu[data-color='red-invert'] .drop-down-tab-bar button[type=submit]:hover, .mega-menu[data-color='red-invert'] .drop-down-tab-bar button[type=reset]:hover {
	background-color: #f5594e
}

.mega-menu[data-color='red-invert'] .drop-down a:hover, .mega-menu[data-color='red-invert'] .drop-down-tab-bar a:hover {
	color: #f32b1e
}

.mega-menu[data-color='red-invert'] .drop-down::-moz-selection, .mega-menu[data-color='red-invert'] .drop-down-tab-bar::-moz-selection {
	background: #f8867f
}

.mega-menu[data-color='red-invert'] .drop-down::selection, .mega-menu[data-color='red-invert'] .drop-down-tab-bar::selection {
	background: #f8867f
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='red-invert'].vertical-left .menu-search-bar input, .mega-menu[data-color='red-invert'].vertical-left .menu-search-bar li, .mega-menu[data-color='red-invert'].vertical-left .menu-search-bar form, .mega-menu[data-color='red-invert'].vertical-left .menu-search-bar label, .mega-menu[data-color='red-invert'].vertical-right .menu-search-bar input, .mega-menu[data-color='red-invert'].vertical-right .menu-search-bar li, .mega-menu[data-color='red-invert'].vertical-right .menu-search-bar form, .mega-menu[data-color='red-invert'].vertical-right .menu-search-bar label {
		background: #f32b1e
	}

}

.mega-menu[data-color='teal'] {
}

.mega-menu[data-color='teal'] > section.menu-list-items {
	background-color: #333
}

.mega-menu[data-color='teal'] .menu-logo > li > a:hover {
	background-color: #009788
}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='teal'] .menu-links > li.activeTriggerMobile {
		background-color: #009788
	}

}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='teal'] .menu-links > li.activeTrigger {
		background-color: #009788
	}

}

.mega-menu[data-color='teal'] .menu-links > li.active {
	background-color: #009788
}

.mega-menu[data-color='teal'] .menu-links > li:hover {
	background-color: #009788
}

.mega-menu[data-color='teal'] .menu-search-bar li:hover i.fas.fa-search {
	background: #009788
}

.mega-menu[data-color='teal'] .menu-search-bar input:focus {
	background: #009788
}

.mega-menu[data-color='teal'] .menu-mobile-collapse-trigger {
	background: #1a1a1a
}

.mega-menu[data-color='teal'] .menu-mobile-collapse-trigger:hover {
	background: black
}

.mega-menu[data-color='teal'] .drop-down-multilevel li:hover {
	background: #009788
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='teal'] .drop-down-multilevel li.activeTrigger {
		background: #009788
	}

}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='teal'] .drop-down-multilevel li.activeTriggerMobile {
		background: #009788
	}

}

.mega-menu[data-color='teal'] .drop-down input[type="submit"], .mega-menu[data-color='teal'] .drop-down input[type="button"], .mega-menu[data-color='teal'] .drop-down button[type=submit], .mega-menu[data-color='teal'] .drop-down button[type=reset], .mega-menu[data-color='teal'] .drop-down-tab-bar input[type="submit"], .mega-menu[data-color='teal'] .drop-down-tab-bar input[type="button"], .mega-menu[data-color='teal'] .drop-down-tab-bar button[type=submit], .mega-menu[data-color='teal'] .drop-down-tab-bar button[type=reset] {
	background: #009788
}

.mega-menu[data-color='teal'] .drop-down input[type="submit"]:hover, .mega-menu[data-color='teal'] .drop-down input[type="button"]:hover, .mega-menu[data-color='teal'] .drop-down button[type=submit]:hover, .mega-menu[data-color='teal'] .drop-down button[type=reset]:hover, .mega-menu[data-color='teal'] .drop-down-tab-bar input[type="submit"]:hover, .mega-menu[data-color='teal'] .drop-down-tab-bar input[type="button"]:hover, .mega-menu[data-color='teal'] .drop-down-tab-bar button[type=submit]:hover, .mega-menu[data-color='teal'] .drop-down-tab-bar button[type=reset]:hover {
	background-color: #333
}

.mega-menu[data-color='teal'] .drop-down a:hover, .mega-menu[data-color='teal'] .drop-down-tab-bar a:hover {
	color: #009788
}

.mega-menu[data-color='teal'] .drop-down::-moz-selection, .mega-menu[data-color='teal'] .drop-down-tab-bar::-moz-selection {
	background: #00fde4
}

.mega-menu[data-color='teal'] .drop-down::selection, .mega-menu[data-color='teal'] .drop-down-tab-bar::selection {
	background: #00fde4
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='teal'].vertical-left .menu-search-bar input, .mega-menu[data-color='teal'].vertical-left .menu-search-bar li, .mega-menu[data-color='teal'].vertical-left .menu-search-bar form, .mega-menu[data-color='teal'].vertical-left .menu-search-bar label, .mega-menu[data-color='teal'].vertical-right .menu-search-bar input, .mega-menu[data-color='teal'].vertical-right .menu-search-bar li, .mega-menu[data-color='teal'].vertical-right .menu-search-bar form, .mega-menu[data-color='teal'].vertical-right .menu-search-bar label {
		background: #009788
	}

}

.mega-menu[data-color='teal-invert'] {
}

.mega-menu[data-color='teal-invert'] > section.menu-list-items {
	background-color: #009788
}

.mega-menu[data-color='teal-invert'] .menu-logo > li > a:hover {
	background-color: #007e71
}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='teal-invert'] .menu-links > li.activeTriggerMobile {
		background-color: #007e71
	}

}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='teal-invert'] .menu-links > li.activeTrigger {
		background-color: #007e71
	}

}

.mega-menu[data-color='teal-invert'] .menu-links > li.active {
	background-color: #007e71
}

.mega-menu[data-color='teal-invert'] .menu-links > li:hover {
	background-color: #007e71
}

.mega-menu[data-color='teal-invert'] .menu-search-bar li:hover i.fas.fa-search {
	background: #007e71
}

.mega-menu[data-color='teal-invert'] .menu-search-bar input:focus {
	background: #007e71
}

.mega-menu[data-color='teal-invert'] .menu-mobile-collapse-trigger {
	background: #00645a
}

.mega-menu[data-color='teal-invert'] .menu-mobile-collapse-trigger:hover {
	background: #00312c
}

.mega-menu[data-color='teal-invert'] .drop-down-multilevel li:hover {
	background: #007e71
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='teal-invert'] .drop-down-multilevel li.activeTrigger {
		background: #007e71
	}

}

@media screen and (max-width:1023px) {
	.mega-menu[data-color='teal-invert'] .drop-down-multilevel li.activeTriggerMobile {
		background: #007e71
	}

}

.mega-menu[data-color='teal-invert'] .drop-down input[type="submit"], .mega-menu[data-color='teal-invert'] .drop-down input[type="button"], .mega-menu[data-color='teal-invert'] .drop-down button[type=submit], .mega-menu[data-color='teal-invert'] .drop-down button[type=reset], .mega-menu[data-color='teal-invert'] .drop-down-tab-bar input[type="submit"], .mega-menu[data-color='teal-invert'] .drop-down-tab-bar input[type="button"], .mega-menu[data-color='teal-invert'] .drop-down-tab-bar button[type=submit], .mega-menu[data-color='teal-invert'] .drop-down-tab-bar button[type=reset] {
	background: #007e71
}

.mega-menu[data-color='teal-invert'] .drop-down input[type="submit"]:hover, .mega-menu[data-color='teal-invert'] .drop-down input[type="button"]:hover, .mega-menu[data-color='teal-invert'] .drop-down button[type=submit]:hover, .mega-menu[data-color='teal-invert'] .drop-down button[type=reset]:hover, .mega-menu[data-color='teal-invert'] .drop-down-tab-bar input[type="submit"]:hover, .mega-menu[data-color='teal-invert'] .drop-down-tab-bar input[type="button"]:hover, .mega-menu[data-color='teal-invert'] .drop-down-tab-bar button[type=submit]:hover, .mega-menu[data-color='teal-invert'] .drop-down-tab-bar button[type=reset]:hover {
	background-color: #009788
}

.mega-menu[data-color='teal-invert'] .drop-down a:hover, .mega-menu[data-color='teal-invert'] .drop-down-tab-bar a:hover {
	color: #007e71
}

.mega-menu[data-color='teal-invert'] .drop-down::-moz-selection, .mega-menu[data-color='teal-invert'] .drop-down-tab-bar::-moz-selection {
	background: #00e4cd
}

.mega-menu[data-color='teal-invert'] .drop-down::selection, .mega-menu[data-color='teal-invert'] .drop-down-tab-bar::selection {
	background: #00e4cd
}

@media screen and (min-width:1024px) {
	.mega-menu[data-color='teal-invert'].vertical-left .menu-search-bar input, .mega-menu[data-color='teal-invert'].vertical-left .menu-search-bar li, .mega-menu[data-color='teal-invert'].vertical-left .menu-search-bar form, .mega-menu[data-color='teal-invert'].vertical-left .menu-search-bar label, .mega-menu[data-color='teal-invert'].vertical-right .menu-search-bar input, .mega-menu[data-color='teal-invert'].vertical-right .menu-search-bar li, .mega-menu[data-color='teal-invert'].vertical-right .menu-search-bar form, .mega-menu[data-color='teal-invert'].vertical-right .menu-search-bar label {
		background: #007e71
	}

}
